<script lang='ts'>
  import { API_URL, getCookie } from '@/helpers/apiCall'
  import { notifier } from '@/helpers/notifier'

  export let type = ''

  let id = ''
  let audio: any
  let uploadForm: any

  const handleUpload = (event: any) => {
    event.preventDefault()
    const formData = new FormData()
    formData.append('audio', audio.files[0])
    formData.append('file_upload', 'true')
    formData.append('id', id)
    formData.append('type', type)

    fetch(API_URL + '/mod/uploadAudio', {
      body: formData,
      credentials: 'include',
      headers: {
        Authorization: 'Bearer ' + getCookie('jwt')
      },
      method: 'POST'
    })
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          notifier.info(data.error)
        } else {
          notifier.success('File uploaded')
        }
        uploadForm.reset()
      })
      .catch(error => {
        console.error(error)
      })
  }
</script>
<div class='page-wrapper'>
  {#if type}
    <h2>Upload audio for <strong>{type}</strong></h2>
    <form bind:this={uploadForm} action='' on:submit={handleUpload}>
      <p>
        <label for='id'>Id</label>
        <input id='id' type='text' bind:value={id} />
      </p>
      <p>
        <label for='audio'>File</label>
        <input
          bind:this={audio}
          id='audio'
          accept='audio/*'
          type='file'
          name='audio'
        />
      </p>
      <input type='submit' value='Upload' />
    </form>
  {:else}
    -
  {/if}
</div>
