import { Dictionary } from '@/definitions/definitions'

export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)

export const formDataUnpack = (formData: FormData) => {
  const value = Object.fromEntries(formData.entries())
  const ret: Dictionary = {}
  for (const i in value) {
    if (i.includes('[')) {
      const tmp = i.replace(/]/g, '').split('[')
      const [t0, t1, t2] = tmp
      if (tmp.length === 2) {
        if (typeof ret[t0] === 'undefined') {
          ret[t0] = {}
        }
        ret[t0][t1] = value[i]
      } else if (tmp.length === 3) {
        if (typeof ret[t0] === 'undefined') {
          ret[t0] = {}
        }
        if (typeof ret[t0][t1] === 'undefined') {
          ret[t0][t1] = {}
        }
        ret[t0][t1][t2] = value[i]
      }
    } else {
      ret[i] = value[i]
    }
  }

  return ret
}

export const isNumber = (n: any) => !isNaN(n) && n !== ''

export const uniqBy = <T>(arr: T[], func: (item: T) => string) => {
  const seen: { [key: string]: boolean } = {}
  return arr.filter((item: T) => {
    const k = func(item)
    if (seen[k]) {
      seen[k] = true
      return false
    }
    seen[k] = true
    return true
  })
}

export const uniqObjects = (el: any) => uniqBy(el, JSON.stringify)

export const removeByItemValue = function (arr: any, key: string, val: any) {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i][key] === val) {
      arr.splice(i, 1)
      return arr // delete this line if you want to remove all items (this remove just first occurence)
    }
  }
  return arr
}

export const toInt = (n: string | number | undefined): number => {
  const num = typeof n === 'number' ? n : parseInt(n || '0', 10)
  return isNaN(num) ? 0 : num
}
