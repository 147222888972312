<!-- eslint-disable max-len -->
<p>In top navigation you can choose different report types. Next to every button you can see the number of reports of that type.</p>
<p>If you click any of these buttons you can see list of reports in that group.</p>
<p>In message column you can write message. Currently (August 2023) that message is just saved in the database, plan is that we will send email to report if message field is populated.</p>
<p>In select list at the end you have 3 types of reports</p>
<ul>
  <li><strong>Mark</strong> - Report will stay, just it will be marked as something we are aware of and we are working on it.</li>
  <li><strong>Approve</strong> - Report will be approved as something we have done, we choose this once we completed action related to report.</li>
  <li><strong>Reject</strong> - Report will be rejected, and we will do nothing about it.</li>
</ul>

<p>We should try to make these list empty all the time. Part of customer support is to keep this page clean, and clear all reports often.</p>
