<!-- eslint-disable max-len -->
<div class='docs-outer'>
  <p>This is just list of values from database</p>
  <ul>
    <li><strong>Levels</strong> - Here you can see how many points you should have for specific level, for example if you have between 201 and 500 points your level is 5.</li>
    <li><strong>Missions</strong> - List of missions from database</li>
    <li><strong>Actions</strong> - they are logged when you do specific action on website. We using them later to calcualte points, calculate goals, calculate guided activities and missions. Even they are not visible direclty on website they are very important,a nd many langoid options are based on them.</li>
    <li><strong>Badges</strong> - List of badges.</li>
  </ul>
</div>
<style lang='scss'>
  .docs-outer {
    max-width: 64rem;
  }
</style>
