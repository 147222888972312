export const removePostSyntax = (str: string) => {
  return str
    ? str
      .replace(/##\d\w*(.*)##/g, '$1')
      .replace(/#@/g, '')
      .replace(/###/g, '')
      .replace(/##/g, '')
      .replace(/%%%/g, '')
      .replace(/%%/g, '')
    : ''
}
