<script lang='ts'>
  import { poses } from '@/helpers/data'
  import { fetchData } from '@/helpers/fetchHelpers'
  import { notifier } from '@/helpers/notifier'

  import Breadcrumb from '@/components/Breadcrumb.svelte'

  document.title = 'Grammar rules order'

  let rules: any = []
  let pos: Record<number, any> = {}
  const posIds = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13] as const
  let inflected: any = {}
  const orders: any = {}
  fetchData('mod/grammarRulesOrderLoad', {})
    .then((data) => {
      if (data.error) {
        notifier.error((data.error))
      } else {
        rules = data.rules
        pos = data.pos || {}
        inflected = data.inflected || []
        for (const i in inflected) {
          const item = inflected[i]
          if (pos[item] === undefined) {
            pos[item] = []
          }
        }
        posIds.forEach((key) => {
          orders[key] = pos[key]?.join(',')
        })
      }
    }).catch((e) => notifier.error(e.message))

  const saveOrder = () => {
    fetchData('mod/grammarRulesOrderSave', { orders })
      .then((data) => {
        if (data.error) {
          notifier.error((data.error))
        } else {
          notifier.success('Saved')
        }
      }).catch((e) => notifier.error(e.message))
  }
</script>

<Breadcrumb />
{#each posIds as p}
  <p><label for='pos{p}'>{poses[p].pos}</label><input id='pos{p}' type='text' bind:value={orders[p]} style='min-width: 80%;' /></p>
{/each}
<input type='submit' value='Save' on:click={saveOrder} />

{#if rules.length}
  <table class='table'>
    <tr>
      <th>ID</th>
      <th>POS</th>
      <th>Name</th>
      <th>Description</th>
    </tr>
    {#each rules as rule}
      <tr>
        <td>{rule.id}</td>
        <td>{rule.pos}</td>
        <td>{rule.name}</td>
        <td>{rule.description}</td>
      </tr>
    {/each}
  </table>
{/if}
