<script lang='ts'>
  import { navigate } from 'svelte-routing'

  import languages from '@/config/languages'

  import { getIdFromIso } from '@/helpers/apiCall'
  import { fetchData } from '@/helpers/fetchHelpers'

  import WordPropsDoc from '@/components/docs/WordPropsDoc.svelte'
  import Documentation from '@/components/Documentation.svelte'
  import Pagination from '@/components/Pagination.svelte'

  interface WordData {
    id: number;
    id_english_word: number;
    pos: number;
    status: string;
    type?: number;
    word: string;
  }

  interface TypeData {
    id: number;
    name: string;
  }

  interface FetchData {
    types: TypeData[];
    words: Record<string, WordData>;
  }

  document.title = 'Word Props'
  export let iso = ''
  export let group = 0
  export let page = 1

  let currentActiveIndex = 0
  let totalPages = 1
  let selectedLanguage = ''
  let languageId = getIdFromIso(iso || selectedLanguage) || 0
  let wordsLIs: any = []
  let isYesNoType = false
  let answerNOidType = 0

  const words: any = {} // this will hold the selected types for each word
  let data: FetchData = {
    types: [],
    words: {}
  } // define a reactive variable for data

  const loadPage = () => {
    languageId = getIdFromIso(iso || selectedLanguage) || 0
    wordsLIs = []
    data = { types: [], words: {} }
    page = page
    fetchData('mod/wordPropsLoad', {
      group,
      languageId,
      page
    }).then((response) => {
      data = response // assign the response to data
      totalPages = response.totalPages
      if (
        data.types?.[0].name === 'yes' &&
        data.types?.[1].name === 'no'
      ) {
        isYesNoType = true
        answerNOidType = data.types?.[1].id
        // eslint-disable-next-line
        console.log({ isYesNoType, answerNOidType })
      }
    })
  }
  loadPage()

  // Function to handle radio change
  const handleRadioChange = (wordId: number, typeId: number) => {
    if (!words[wordId]) {
      words[wordId] = {}
    }

    words[wordId] = { groupId: group, typeId, wordId }
  }

  // Function to handle save button click
  const handleSave = () => {
    fetchData('mod/wordPropsSave', {
      languageId,
      words: Object.values(words)
    }).then((response) => {
      console.error(response)
    })
  }

  function handleKeydown(event: KeyboardEvent) {
    const enteredNumber = parseInt(event.key)
    if (enteredNumber > 0 && enteredNumber <= data.types.length) {
      const selectedItem = wordsLIs[currentActiveIndex]
      if (selectedItem) {
        const radioButton = selectedItem.querySelector(`input[value='${data.types[enteredNumber - 1].id}']`)

        if (radioButton) {
          radioButton.checked = true
          handleRadioChange(radioButton.getAttribute('name').split('-')[1], data.types[enteredNumber - 1].id)
        }
        currentActiveIndex++
      }
    }
    if (event.key === 'ArrowLeft' && currentActiveIndex > 0) {
      event.preventDefault()
      currentActiveIndex--
    } else if (event.key === 'ArrowRight' && currentActiveIndex < wordsLIs.length - 1) {
      event.preventDefault()
      currentActiveIndex++
    } else if (event.key === 'ArrowUp' && currentActiveIndex > 4) {
      event.preventDefault()
      currentActiveIndex -= 5
    } else if (event.key === 'ArrowDown' && currentActiveIndex < wordsLIs.length - 5) {
      event.preventDefault()
      currentActiveIndex += 5
    }
  }
</script>
<svelte:window on:keydown={handleKeydown} />
{#key page}
  {#if iso === ''}
    <label for='language-select'>Select a Language:</label>
    <div class='custom-select'>
      <select id='language-select' bind:value={selectedLanguage} on:change={loadPage}>
        <option value=''>Choose a language</option>
        {#each languages as lang}
          <option value={lang.iso}>{lang.name} ({lang.iso})</option>
        {/each}
      </select>
    </div>
  {/if}
  <h2>Word properties {iso ? `For ${iso}` : 'Universal'}</h2>
  <div class='legend-wrapper'>
    <h3>Legend:</h3>
    {#each data.types as type (type.id)}
      <div class='legend-item'>
        <div class='colorBox color-radio-{type.id}'>{1 + data.types.indexOf(type)}</div>
        {type.name}
      </div>
    {:else}
      <h1>No types added for current group! Please add types to use this page.</h1>
    {/each}
  </div>
  <ul class='word-list'>
    {#each Object.values(data.words || {}) as word,index}
      <li
        bind:this={wordsLIs[index]}
        class='word'
        class:-active={currentActiveIndex ===index}
      >
        <div
          role='button'
          tabindex={0}
          on:click={() => {currentActiveIndex = index}}
          on:keypress={() => {}}
        >

          <div class='wordContent'>{word.word}</div>
          {#each data.types || [] as type (type.id)}
            {@const id = word.id}
            <input
              id={`type-${type.id}-word-${id}`}
              class='color-radio'
              checked={type.id === word.type}
              type='radio'
              value={type.id}
              on:change={() => handleRadioChange(word.id, type.id)}
              name={`word-${id}`} />
            <label for={`type-${type.id}-word-${id}`}>{type.name}</label>
          {/each}
        </div>
      </li>
    {/each}
  </ul>
  <button type='button' on:click={handleSave}>Save</button>
  <Pagination count={totalPages}
              current={+page}
              on:change={(e) => {
    page = parseInt(e.detail)
    navigate(`/${iso}/word-props/${group}/${page}`)
    setTimeout(loadPage,500)
  }} />
{/key}
<Documentation>
  <WordPropsDoc />
</Documentation>
<style lang='scss'>
  .word-list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    list-style-type: none;

    > .word {
      padding: 1rem;
      background: var(--gray-2);
      border: solid var(--gray-2) 1px;

      &.-active {
        color: var(--black-text);
        background: var(--white-1);
        outline: solid lime 0.2rem;
      }

      > div > .wordContent {
        font-size: 2rem;
      }
    }
  }

  /* Hide the radio button itself */
  .color-radio {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
  }

  /* Create squares that represent the visual radio button */
  .color-radio + label {
    display: inline-block;
    margin: 0.2rem;
    padding: 0.2rem;
    background: var(--white-background);
    border-radius: 0.4rem;
    cursor: pointer;
    opacity: 0.8;
  }

  li:has(.color-radio:nth-of-type(1):checked) {
    background: var(--red-3);
  }

  li:has(.color-radio:nth-of-type(2):checked) {
    background: var(--green-3);
  }

  li:has(.color-radio:nth-of-type(3):checked) {
    background: var(--blue-3);
  }

  li:has(.color-radio:nth-of-type(4):checked) {
    background: var(--orange-3);
  }

  li:has(.color-radio:nth-of-type(5):checked) {
    background: var(--pink-3);
  }

  .color-radio:nth-of-type(1) + label {
    color: var(--white-1);
    background: var(--red-3);
  }

  .color-radio:nth-of-type(2) + label {
    color: var(--white-1);
    background: var(--green-3);
  }

  .color-radio:nth-of-type(3) + label {
    color: var(--white-1);
    background: var(--blue-3);
  }

  .color-radio:nth-of-type(4) + label {
    color: var(--white-1);
    background: var(--orange-3);
  }

  .color-radio:nth-of-type(5) + label {
    color: var(--white-1);
    background: var(--pink-3);
  }

  /* When the radio button is selected, add a border to the square */
  .color-radio:checked + label {
    background-color: transparent;
    outline: solid black 1px;
    opacity: 1;
  }

  /* Styles for the legend */
  .legend-wrapper {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-bottom: 1rem;
  }

  .legend-item {
    display: flex;
    align-items: center;

    > .colorBox {
      width: 2rem;
      height: 2rem;
      margin-right: 0.4rem;
      font-weight: bold;
      text-align: center;
      color: var(--white-1);
      border-radius: 50%;
      opacity: 0.8;
    }

    &:nth-of-type(1) > .colorBox {
      background-color: var(--red-3);
    }

    &:nth-of-type(2) > .colorBox {
      background-color: var(--green-3);
    }

    &:nth-of-type(3) > .colorBox {
      background-color: var(--blue-3);
    }

    &:nth-of-type(4) > .colorBox {
      background-color: var(--orange-3);
    }

    &:nth-of-type(5) > .colorBox {
      background-color: var(--pink-3);
    }
  }
</style>
