<script lang='ts'>
  import { fetchData } from '@/helpers/fetchHelpers'

  export let type = ''
  export let id = 0

  let entry: any = {}

  fetchData('mod/getEntry', { id, type })
    .then((data) => {
      entry = data
    })
    .catch((err) => {
      console.error(err)
    })
</script>
<div class='page-wrapper'>
  {#if entry}
    <h2>{type} - id: {id}</h2>
    <table>
      {#each Object.keys(entry) as key}
        <tr><td>{key}</td><td>{entry[key]}</td></tr>
      {/each}
    </table>
  {/if}
</div>
