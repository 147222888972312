<script lang='ts'>
  import { onMount } from 'svelte'

  import { Func } from '@/definitions/definitions'

  export let current: number, total: number, onNext: Func, onPrev: Func

  onMount(() => {
    const handleOnKeyUp = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.key === 'ArrowLeft') {
        onPrev()
      } else if (e.ctrlKey && e.key === 'ArrowRight') {
        onNext()
      }
    }

    document.addEventListener('keyup', handleOnKeyUp)

    return () => {
      document.removeEventListener('keyup', handleOnKeyUp)
    }
  })
</script>

<div class='prev-next-box'>
  {#if current > 1}<button class='prev' type='button' on:click={onPrev}>Prev</button>{/if}
  {#if current < total}<button class='next' type='button' on:click={onNext}>Next</button>{/if}
</div>

<style lang='scss'>
  .prev-next-box {
    position: relative;
    width: 100%;
    height: 6rem;
    margin: 2rem 0;
    padding-top: 2rem;

    > .prev,
    > .next {
      position: absolute;
      top: 0;
      display: flex;
      align-items: center;
      width: 6rem;
      height: 6rem;
      color: var(--white-text);
      background: var(--gray-8);
    }

    > .prev {
      left: 0;
      justify-content: flex-end;
      padding-right: 0.6rem;
      clip-path: polygon(100% 0, 0 50%, 100% 100%);
    }

    > .next {
      right: 0;
      justify-content: flex-start;
      padding-left: 0.6rem;
      clip-path: polygon(0 0, 0% 100%, 100% 50%);
    }
  }
</style>
