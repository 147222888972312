type LogArgs = [message?: unknown, ...optionalParams: unknown[]];

export const logger = {
  error: (...args: LogArgs) => {
    console.error(...args)
  },
  log: (...args: LogArgs) => {
    // eslint-disable-next-line no-console
    console.log(...args)
  }
}
