<script lang='ts'>
  // this page is used by admins to asign users to batches for WIFs

  import { getIdFromIso } from '@/helpers/apiCall'
  import { fetchData } from '@/helpers/fetchHelpers'
  import { hasLevel } from '@/helpers/game'
  import { notifier } from '@/helpers/notifier'

  import Breadcrumb from '@/components/Breadcrumb.svelte'
  import WifAssignDoc from '@/components/docs/admin/WifAssignDoc.svelte'
  import Documentation from '@/components/Documentation.svelte'

  document.title = 'Wiff assign'

  export let iso = ''
  const lang = getIdFromIso(iso)
  let userBatches: any[] = []

  const save = (e: any) => {
    const batchEl: HTMLInputElement = document.getElementById('batch') as HTMLInputElement
    const manualEl: HTMLInputElement = document.getElementById('manual') as HTMLInputElement
    const userEl: HTMLInputElement = document.getElementById('user') as HTMLInputElement
    e.preventDefault()
    const requestData = {
      batch: batchEl.value,
      lang,
      manual: manualEl?.value,
      user: userEl?.value
    }
    fetchData('mod/wifAssign', requestData).then((data) => {
      if (data.error) {
        notifier.error(data.error)
      } else {
        userBatches = data.batches || []
        e.target.reset()
      }
    })
  }
  const changeBatch = (e: any) => {
    const el = document.getElementById('manualBlock')
    if (el) {
      el.style.display = e.target.value === 'manual' ? 'block' : 'none'
    }
  }
  const batches = ['manual', '150', '500', '1500', '5000', '20000']
</script>

<Breadcrumb />
{#if hasLevel(1)}
  <p>If you want to see assigned batches you can specify just userId.</p>
  <form action='' on:submit={save}>
    <p><label for='user'>User id or email</label> <input id='user' type='text' /></p>
    <div>
      <label for='batch'>Batch</label>
      <div class='custom-select'>
        <select id='batch' on:change={changeBatch}>
          <option value=''>Choose</option>
          {#each batches as batch}
            <option value={batch}>{batch}</option>
          {/each}
        </select>
      </div>
    </div>
    <p id='manualBlock' style='display: none;'>
      Manual ids <textarea id='manual' cols='30' rows='10' />
    </p>
    <input type='submit' value='Save' />

  </form>
{:else}
  <p>You are not admin</p>
{/if}
{#if userBatches.length}
  <table class='table'>
    <tr>
      <th>id</th>
      <th>Language</th>
      <th>Size</th>
      <th>Manual</th>
    </tr>
    {#each userBatches as batch}
      <tr>
        <td>{batch.id}</td>
        <td>{batch.language}</td>
        <td>{batch.size}</td>
        <td>{batch.manual}</td>
      </tr>
    {/each}
  </table>
{/if}
<Documentation>
  <WifAssignDoc />
</Documentation>
<style lang='scss'>
  form {
    padding: 2rem;
  }
</style>
