<script lang='ts'>
  /* eslint-disable sort-keys */
  /* todo - explain:
  * - how user getting new level
  * - how many points will user get for specific action
  * - how many points will user get for specific mission
  * - possible missions
 */
  import { link } from 'svelte-routing'

  import { fetchData } from '@/helpers/fetchHelpers'

  import DocsDoc from '@/components/docs/DocsDoc.svelte'
  import Documentation from '@/components/Documentation.svelte'
  import PrintTable from '@/components/PrintTable.svelte'
  import Table from '@/components/Table.svelte'

  export let page = ''

  document.title = 'DOCS - ' + page

  let data: any = {}
  $: fetchPage(page)

  function fetchPage (page: string) {
    if (page !== '') {
      fetchData('mod/docs', { page }).then((dataReturned) => {
        data = dataReturned
      // console.log(data)
      })
    }
  }
</script>
<h2>
  {#if page}{page}{:else}Documentation{/if}
</h2>
<a href='/docs/game-levels' use:link>Levels</a> |
<a href='/docs/game-missions' use:link>Missions</a> |
<a href='/docs/game-actions' use:link>Actions</a> |
<a href='/docs/game-badges' use:link>Badges</a> |
{#if page === 'game-levels'}
  <p>Ranges for levels are used to define how many points you should have to get new level.</p>
  <!-- eslint-disable camelcase -->
  <Table
    columns={{
      id: 'Level',
      points_from: 'From',
      points_to: 'To'
    }}
    {data} />
{:else if page === 'game-actions'}
  <p>In points column you can see how many points you will get for completting specific action.</p>
  <p>Implemented column says if specific action is implemented, so you can track progress.</p>
  <Table
    columns={{
      id: 'Id',
      action: 'Action',
      category: 'Category',
      implemented: 'Implemented',
      points: 'Points',
      description: 'Description'
    }}
    {data} />
{:else if page === 'game-missions'}
  <p>In points column you can see how many points you will get for completting specific mission.</p>
  <p>Implemented column says if specific mission is implemented, so you can track progress.</p>
  <Table
    columns={{
      id: 'Id',
      name: 'Name',
      description: 'Description',
      points: 'Points',
      implemented: 'Implemented',
      priority: 'Priority',
      mission_group: 'Group',
      slug: 'Slug',
      status: 'Status'
    }}
    {data} />
{:else if page === 'game-badges'}
  <p>Badges are awarded for specific actions.</p>
  <p>Implemented column says if specific badge is implemented, so you can track progress.</p>
  <Table
    columns={{
      id: 'Id',
      content: 'Content',
      description: 'Description',
      id_badge_group: 'Name',
      implemented: 'Implemented'
    }}
    {data} />
{:else}
  <PrintTable {data} />
{/if}
<Documentation dateCompleted='2023-08-26'>
  <DocsDoc />
</Documentation>
