import { toast } from '@zerodevx/svelte-toast'

export const notifier = {
  error: (message: string) => {
    toast.push(message, { theme: { '--toastBackground': '#fb4878', '--toastBarBackground': '#f52f5a' } })
  },
  info: (message: string) => {
    toast.push(message)
  },
  success: (message: string) => {
    toast.push(message, { theme: { '--toastBackground': '#48bb78', '--toastBarBackground': '#2f855a' } })
  },
  warning: (message: string) => {
    toast.push(message, { theme: { '--toastBackground': '#e8d444', '--toastBarBackground': '#d8c424' } })
  }
}
