<script lang='ts'>
  import { onMount } from 'svelte'

  import { fetchData } from '@/helpers/fetchHelpers'
  import getParameterByName from '@/helpers/getParameterByName'
  import { notifier } from '@/helpers/notifier'

  import TranslateWordDoc from '@/components/docs/words/TranslateWordDoc.svelte'
  import Documentation from '@/components/Documentation.svelte'
  import HiddenPagesBoundary from '@/components/HiddenPagesBoundary.svelte'
  import NavigationButtons from '@/components/NavigationButtons.svelte'
  import PageInfobox from '@/components/PageInfobox.svelte'
  import SwipeHandlers from '@/components/SwipeHandlers.svelte'
  import WordContainer from '@/components/WordContainer.svelte'

  document.title = 'Translate word'

  export let iso: string

  let allWords: any = []
  let inputExtra: any, inputContent: any, form: any // elements
  let page = parseInt(getParameterByName('page') || '1') || 1
  let pagesCount = 0
  let currentWord: any = {}
  let translations: any = []
  let submissions: any = []
  let editTransId: any
  let hideTranslated = false

  $: translatedCount = allWords.filter((item: any) => !!item.translation_id).length
  $: isCurrentPageTranslated = !!allWords[page - 1]?.translation_id
  $: areAllTranslated = translatedCount === pagesCount

  onMount(() => {
    const localSettings = localStorage.getItem('hideTranslatedPages')
    if (localSettings) hideTranslated = JSON.parse(localSettings)
  })

  const loadPage = (pg: number, all = false) => {
    page = pg

    fetchData('mod/translateWordLoad', {
      all,
      page: pg
    }).then((data: any) => {
      if (all) {
        allWords = data.originals
        pagesCount = data.originals.length
      }

      setTimeout(() => {
        // wait to calculate isCurrentPageTranslated && !areAllTranslated
        if (hideTranslated && pg === 1 && isCurrentPageTranslated && !areAllTranslated) {
          handlePageChange('next')
        }

        currentWord = data.word
        translations = data.word.translations
        submissions = data.submissions
        window.history.replaceState({}, document.title, location.search.split('?')[0] + '?page=' + pg)
      })
    })
  }

  /*
  const fillTranslations = (words) => {
    const temp = {}
    words.forEach((word, index) => {
      temp[index + 1] = !!word.translation_id
    })
    return temp
  }
  */

  const handleFormSubmit = (e: any) => {
    e.preventDefault()

    const submitType = e.submitter.name
    const content = e.target.content.value
    const extra = e.target.extra.value

    saveTranslation(content, extra, submitType)
  }

  const saveTranslation = (content: string, extra: any, submitType: any) => {
    if (content.trim()) {
      fetchData('mod/translateWordSave', {
        content,
        extra,
        id: currentWord.english.id,
        update: editTransId
      })
        .then((data) => {
          notifier.info('Saved')

          if (submitType === 'save-and-continue') {
            loadPage(page + 1)
          } else {
            if (editTransId) {
              translations = translations.map((item: any) => (item.id === data.id ? { ...item, content, extra } : item))
            } else {
              allWords[page - 1].translation_id = data.id
              translations = [...translations, { content, extra, id: data.id }]
            }
          }
          editTransId = undefined
          form.reset()
        })
        .catch((e) => {
          notifier.warning(e.message)
        })
    } else {
      notifier.warning('Content cannot be empty')
    }
  }

  const removeTranslation = (id: any) => {
    const confirm = window.confirm('Are you sure?')
    if (confirm) {
      fetchData('mod/translateWordDelete', { id }).then(() => {
        translations = translations.filter((item: any) => item.id !== id)
        if (translations.length === 0) allWords[page - 1].translation_id = ''
      })
    }
  }

  const editTranslation = (id: any) => {
    const trans = translations.find((item: any) => item.id === id)
    editTransId = trans.id
    inputContent.value = trans.content
    inputExtra.value = trans.extra
  }

  const resetForm = () => {
    editTransId = undefined
    form.reset()
  }

  const handlePageChange = (direction: 'prev' | 'next') => {
    if (editTransId) {
      notifier.warning('Cannot change the page while the edit mode is activated')
      return
    }

    if (direction === 'prev' && page > 1) {
      const prev = hideTranslated ? findPrevUntranslated(page) || page : page - 1
      loadPage(prev)
    } else if (direction === 'next' && page < pagesCount) {
      const next = hideTranslated ? findNextUntranslated(page) || page : page + 1
      loadPage(next)
    }

    setTimeout(() => {
      if (inputContent) inputContent.focus()
    })
  }

  const findPrevUntranslated: any = (current: number) => {
    const prev = current - 1

    if (prev > 1) {
      return allWords[prev - 1].translation_id ? findPrevUntranslated(prev) : prev
    }

    notifier.warning('There is no next untranslated page')
  }

  const findNextUntranslated: any = (current: number) => {
    const next = current + 1

    if (next < pagesCount) {
      return allWords[next - 1].translation_id ? findNextUntranslated(next) : next
    }

    notifier.warning('There is no previous untranslated page')
  }

  const handleOnEnter = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      const submitType = e.shiftKey ? 'save' : 'save-and-continue'
      saveTranslation(inputContent.value, inputExtra.value, submitType)
    }
  }

  const publishTranslation = (publish: any, idCorrection: any) => {
    fetchData('mod/translateWordPublish', {
      idCorrection,
      publish
    })
      .then(() => {
        notifier.success(`${publish === 'yes' ? 'Published' : 'Rejected'} successfully`)
      })
      .catch((e) => {
        notifier.warning(e.message)
      })
  }

  const handleHideTranslatedPages = () => {
    hideTranslated = !hideTranslated
    localStorage.setItem('hideTranslatedPages', JSON.stringify(hideTranslated))
  }

  loadPage(page, true)
</script>

<SwipeHandlers onDown={() => {}} onLeft={() => handlePageChange('prev')} onRight={() => handlePageChange('next')} onUp={() => {}} />
<div class='translate-word-page'>
  {#if Object.keys(currentWord).length === 0}
    <div>loading...</div>
  {:else}
    <h1>Add translation for word below</h1>

    <PageInfobox
      checked={hideTranslated}
      current={page}
      done={translatedCount}
      handleClickOnHide={handleHideTranslatedPages}
      pageType='translate-word'
      total={pagesCount} />

    <HiddenPagesBoundary
      areAllFinished={areAllTranslated}
      hideFinished={hideTranslated}
      isCurrentFinished={isCurrentPageTranslated}
      pageType='translate-word'>
      <p>Write translation for:</p>

      <form bind:this={form} action='' on:submit={handleFormSubmit}>
        <input bind:this={inputContent} placeholder='Write translation here' type='text' on:keydown={handleOnEnter} name='content' />
        <div class='custom-select'>
          <select bind:this={inputExtra} on:keydown={handleOnEnter} name='extra'>
            {#each ['none', 'misspelled', 'archaic', 'wrong', 'rude'] as extra}
              <option value={extra}>{extra.toUpperCase()}</option>
            {/each}
          </select>
        </div>
        <input class='default-button' type='submit' value='Save' name='save' />
        {#if !editTransId}
          <input class='default-button' type='submit' value='Save & Continue' name='save-and-continue' />
        {:else}<input class='default-button' type='reset' value='Reset' on:click={resetForm} />{/if}
      </form>
      <WordContainer {currentWord} {iso} />

      {#if translations.length > 0}
        <div class='translation-box'>
          {#each translations as item}
            <div>
              <span>{item.content.toUpperCase()}</span>
              <button class='default-button' type='button' on:click={() => editTranslation(item.id)}>Edit</button>
              <button class='default-button' type='button' on:click={() => removeTranslation(item.id)}>Remove</button>
            </div>
          {/each}
        </div>
      {/if}

      {#if submissions.length > 0}
        <div class='submissions-box'>
          <h2>Submitted translations</h2>
          {#each submissions as item}
            <div>
              <span>{item.content.toUpperCase()}</span>
              <button class='default-button' type='button' on:click={() => publishTranslation('yes', item.id)}>Publish</button>
              <button class='default-button' type='button' on:click={() => publishTranslation('no', item.id)}>Reject</button>
            </div>
          {/each}
        </div>
      {/if}
    </HiddenPagesBoundary>

    {#if !areAllTranslated}
      <NavigationButtons current={page} onNext={() => handlePageChange('next')} onPrev={() => handlePageChange('prev')} total={pagesCount} />
    {/if}
  {/if}
</div>
<Documentation>
  <TranslateWordDoc />
</Documentation>
<style lang='scss'>
  .translate-word-page {
    max-width: 76.8rem;
    margin: auto;
  }
</style>
