<!-- Original is in Front project, if we change anything copy it here -->
<script lang='ts'>
  import { afterUpdate, createEventDispatcher } from 'svelte'

  import { autoPlayState } from '@/store'

  const dispatch = createEventDispatcher()

  export let src = ''
  export let forAutoPlay = false
  export let mini = false
  export let fullSrc = ''

  let audioSrc = ''
  let audioPlayer: HTMLAudioElement
  let audioPlayerButton: HTMLDivElement
  let progressCircle: SVGCircleElement
  let disabled = false
  let autoPlaying = false

  $: if ($autoPlayState && audioSrc && !autoPlaying && forAutoPlay) {
    reMountAudio()
  }

  afterUpdate(() => {
    const sameTrack = audioSrc === src
    audioSrc = fullSrc || src
    disabled = audioSrc.length === 0

    if (!sameTrack) {
      audioPlayer.load()
      audioPlayerButton.classList?.remove('-playing')
      if (forAutoPlay) {
        reMountAudio()
      }
    }

    if (forAutoPlay) {
      if ($autoPlayState && autoPlaying) {
        if (!audioSrc) {
          reMountAudio()
          dispatch('canGoToTheNext')
        }
        if (audioPlayer.paused) playAudio(audioPlayer)
      }
      if (!$autoPlayState && autoPlaying) {
        if (!audioPlayer.paused) playAudio(audioPlayer)
        if (sameTrack) audioPlayerButton.classList?.remove('-playing')
      }
    }
    // todo if possible find another solution to select elements
    progressCircle.setAttribute('style', 'stroke:none;')
  })

  function handleClick (e: any) {
    e.stopImmediatePropagation()

    if (forAutoPlay) {
      // todo add stop to autoplay if we pause sentence on top or if autoplay is on, then disable the button for sentence
      autoPlayState.off()
    }

    playAudio(audioPlayer)
  }

  function reMountAudio () {
    autoPlaying = false
    setTimeout(() => {
      autoPlaying = true
    }, 0)
  }

  function playAudio (audio: HTMLAudioElement) {
    if (audio.paused === false) {
      audio.pause()
      audioPlayerButton.classList?.remove('-playing')
    } else {
      audioPlayerButton.classList?.add('-playing')
      audio.play()
    }

    audio.removeEventListener('timeupdate', initProgressBar)
    audio.addEventListener('timeupdate', initProgressBar)

    audio.onended = function () {
      audioPlayerButton.classList?.remove('-playing')
      progressCircle.setAttribute('style', 'stroke:none')
      if (forAutoPlay) {
        if ($autoPlayState) dispatch('canGoToTheNext')
      }
    }
  }
  function initProgressBar (track: any) {
    calculatePercentPlayed(track)
  }

  function calculatePercentPlayed (track: any) {
    const timePercent = (track.target.currentTime / track.target.duration) * 100
    trackProgress(timePercent)
  }

  function trackProgress (timePercent: number) {
    const radius = parseInt(progressCircle?.getAttribute('r') || '')
    const total = 2 * radius * Math.PI
    const angle = timePercent * (total / 100)

    if (timePercent) {
      progressCircle.setAttribute('style', 'stroke:#883a5a;')
      progressCircle.setAttribute('stroke-dasharray', angle + ', 20000')
    }
    if (angle >= total) {
      progressCircle.setAttribute('style', 'stroke:#883a5a;')
      autoPlaying = false
    }
  }
</script>

<div class='audio-player' class:-disabled={disabled} class:-mini={mini}>
  <audio bind:this={audioPlayer} src={fullSrc || `https://langoid.com${audioSrc}`} tabindex='0' />
  <div
    bind:this={audioPlayerButton}
    class='control'
    class:-playing={autoPlaying}
    role='button'
    tabindex='0'
    on:click={handleClick}
    on:keypress={() => {}} />
  <svg class='progress-container' class:-mini={mini}>
    <circle class='progressCircle background' cx='50%' cy='50%' r='20px' />
    <circle bind:this={progressCircle} class='progressCircle' cx='50%' cy='50%' r='20px' />
  </svg>
</div>

<style lang='scss'>

  .progress-container {
    position: absolute;
    top: -7px;
    left: -9px;
    z-index: 1;
    width: 7.2rem;
    height: 7.2rem;

    > .progressCircle {
      position: relative;
      z-index: 5;
      transform: rotate(-90deg) scale(1.45);
      transform-origin: center;
      fill: none;
      stroke: none;
      stroke-width: 0.4rem;
      stroke-linecap: square;
    }

    &.-mini {
      top: 0;
      z-index: 1;
      width: 4.8rem;
      height: 4.8rem;

      > .progressCircle {
        transform: rotate(-90deg) scale(0.9);
      }
    }
  }

  .audio-player {
    position: relative;
    display: inline-block;
    width: 4.8rem;
    height: 4.8rem;
    margin-right: 4.8rem;
    margin-left: 1.2rem;

    @media (max-width: 768px) {
      margin-right: 1rem;
      margin-left: 0;
    }

    > .control {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
      display: block;
      font-size: 2.4rem;
      line-height: 1.4;
      text-align: center;
      background: url(/images/play.svg) no-repeat center;
      border-radius: 50%;
      cursor: pointer;
    }

    > .control.-playing {
      background: url(/images/pause.svg) no-repeat center;
    }

    &.-disabled {
      display: none;
    }

    &.-mini {
      height: 4.8rem;
      margin-right: 1rem;
    }
  }
</style>
