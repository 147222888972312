<script lang='ts'>
  import { link } from 'svelte-routing'

  import { hasLevel } from '@/helpers/game'

  export let to = '',
    level = 0
</script>

<li class='list-row' class:-hidden={!hasLevel(level)}>
  <a href={to} use:link><slot /></a>
</li>
<style lang='scss'>
  .list-row {
    &.-hidden {
      display: none;
    }

    > :global(a) {
      display: block;
      max-width: 19.2rem;
      min-height: 4.2rem;
      padding: 0.2rem 1rem;
      color: var(--blue-8);
      background: var(--blue-1);

      &:hover {
        color: var(--blue-9);
        background: var(--blue-2);
      }
    }
  }
</style>
