import { writable } from 'svelte/store'

function autoPlay (initial: boolean) {
  const store = writable(initial)

  return {
    ...store,
    off: () => store.set(false),
    toggle: () => store.update((n) => !n)
  }
}

export const autoPlayState = autoPlay(false)
