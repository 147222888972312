<!-- suppress XmlDuplicatedId -->
<script lang='ts'>
  export let key = ''
  export let fields: any
  export let row: any = {}
  export let prefix = 'field'
  let value = ''
  $:if (row) {
    value = row[key + 'OriginalEntry'] || row[key] || ''
  }
  const onChange = (tk: any) => {
    const value = fields[key].options[tk]
    row = row
    row[key] = value
    row[key + 'OriginalEntry'] = value
  }

  const getSelectedIndex = (e: Event) => {
    const target = e.target as HTMLSelectElement
    return target.selectedIndex
  }
</script>
{#if key && fields}
  {@const id = prefix + key}
  {@const fieldType = fields[key].type}
  {#if fieldType === 'number'}
    <input {id} type='number' {value} name={key} />
  {:else if fieldType === 'float'}
    <input {id} step={fields[key].step || '0.01'} type='number' {value} name={key} />
  {:else if fieldType === 'date'}
    <input {id} type='date' {value} name={key} />
  {:else if fieldType === 'datetime'}
    <input {id} type='text' {value} name={key} />
  {:else if fieldType === 'bool'}
    <input {id} checked={!!value} type='checkbox' value='1' name={key} />
  {:else if fieldType === 'email'}
    <input {id} type='email' {value} name={key} />
  {:else if fieldType === 'file'}
    <input {id} type='file' name={key} /> {value}
  {:else if fieldType === 'text'}
    <textarea {id} cols='30' rows='5' name={key}>{value}</textarea>
  {:else if fieldType === 'select'}

    <div class='custom-select'>
      <select {id} on:change={(e) => { onChange(getSelectedIndex(e)) }} name={key}>
        {#each Object.keys(fields[key].options) as optionKey}
          <option selected={value === optionKey} value={optionKey}>{fields[key].options[optionKey]}</option>
        {/each}
      </select>
    </div>
  {:else}
    <input {id} type='text' {value} name={key} />
  {/if}
{/if}
