<script lang='ts'>
  import languages from '@/config/languages'

  import { fetchData } from '@/helpers/fetchHelpers'
  import { notifier } from '@/helpers/notifier'

  import CliDoc from '@/components/docs/admin/dev/CliDoc.svelte'
  import Documentation from '@/components/Documentation.svelte'

  export let iso = ''

  let script = ''
  let loading = false
  const post: any = {
    language: undefined,
    overwrite: 0,
    types: '1,3,4,6,7'
  }

  const forms: any = {
    addLanguageLetters: {},
    cliUpdateComplexity: {},
    cliUpdateSentencesOrder: {},
    cliUpdateWordsLevel: {},
    generateTests: {},
    insertExampleSentences: {},
    moderateAudioSentences: {},
    moderateAudioWords: {},
    wordForms: {}
  }
  const labels: any = {
    addLanguageLetters: 'Add language letters',
    cliUpdateComplexity: 'Update complexity',
    cliUpdateSentencesOrder: 'Update sentences order',
    cliUpdateWordsLevel: 'Update words level',
    generateTests: 'Generate tests',
    insertExampleSentences: 'Insert example sentences',
    moderateAudioSentences: 'Moderate audio sentences',
    moderateAudioWords: 'Moderate audio words',
    wordForms: 'Word forms'
  }

  const load = async () => {
    loading = true
    const dataToSend = script === 'generateTests'
      ? post
      : { language: post.language }
    const postData = await fetchData('cli/' + script, dataToSend)
    loading = false
    if (postData.error) {
      notifier.info(postData.error)
    } else {
      notifier.success(postData.message)
    }
  }
</script>

{#if loading}
  <div class='loading-wrapper'>
    <div class='loading-message'>Loading ...</div>
  </div>
{/if}

Choose script:
<div class='custom-select'>
  <select id='' bind:value={script} name=''>
    <option value=''>Choose</option>
    {#each Object.keys(forms) as index}
      <option value={index}>{labels[index] || index}</option>
    {/each}
  </select>
</div>

{#if script === 'insertExampleSentences'}
  <h1>Insert example sentences</h1>
{:else if script === 'cliUpdateComplexity'}
  <h1>Update complexity</h1>

{:else if script === 'cliUpdateSentencesOrder'}
  <h1>Update sentences order</h1>

{:else if script === 'cliUpdateWordsLevel'}
  <h1>Update words level</h1>

{:else if script === 'addLanguageLetters'}
  <h1>Add language letters</h1>

{:else if script === 'wordForms'}
  <h1>Word forms</h1>

{:else if script === 'moderateAudioWords'}
  <h1>Moderate audio words</h1>

{:else if script === 'moderateAudioSentences'}
  <h1>Moderate audio sentences</h1>

{:else if script === 'generateTests'}
  <h1>Generate tests</h1>
{/if}
<form action='' on:submit|preventDefault={load}>
  <div>
    {#if ['insertExampleSentences', 'cliUpdateComplexity', 'addLanguageLetters', 'wordForms', 'generateTests', 'cliUpdateSentencesOrder', 'cliUpdateWordsLevel'].includes(script) && !iso}
      Language

      <div class='custom-select'>
        <select required bind:value={post.language}>
          <option value=''>Choose</option>
          {#each languages as l}
            <option value={l.id}>{l.name}</option>
          {/each}
        </select>
      </div>
    {/if}
  </div>
  {#if script === 'generateTests'}
    <div>
      Types: <input type='text' bind:value={post.types} name='types' />
    </div>
    <div>
      Overwrite: <input type='checkbox' bind:checked={post.overwrite} name='overwrite' />
    </div>
  {/if}

  {#if script}
    <input type='submit' value='Save' />
  {/if}
</form>

<Documentation>
  <CliDoc />
</Documentation>
<style>
  .loading-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  .loading-message {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 2em;
    color: var(--white-text);
    transform: translate(-50%, -50%);
  }
</style>
