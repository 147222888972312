<script lang='ts'>
  import languages from '@/config/languages'

  import { poses } from '@/helpers/data'
  import { toInt } from '@/helpers/mix'

  export let iso: string,
    currentWord: any = {}

  const language = languages.find((i) => i.iso === iso)
</script>
<div class='word-container'>
  <span>Language: {language?.name}</span>
  <hr />
  <div><span class='word'>{currentWord.word}</span>
    {currentWord.englishWord} <span class='word-pos'>{poses.find(el => toInt(el.id) === toInt(currentWord.pos))?.pos || ''}</span>
  </div>
  <p><strong>Definition:</strong> {currentWord.definition || currentWord.englishDefinition || ''}</p>
  <hr />
  ID:
  {currentWord.englishId || ''}
  <slot />
</div>

<style lang='scss'>
  .word-container {
    max-width: 64rem;
    margin: 2rem auto 3.2rem;
    padding: 1rem;
    color: var(--white-text);
    background: var(--gray-8);

    > p {
      color: var(--white-text);
    }

    > div {
      margin-bottom: 2rem;

      > .word {
        font-size: 3.6rem;
      }
    }

    @media (max-width: 612px) {
      padding: 1rem;
    }
  }

  .word-pos {
    padding-left: 1rem;
    font-size: 2.4rem;
    color: var(--red-3);
  }
</style>
