<script lang='ts'>
  import languages from '@/config/languages'

  import { fetchData } from '@/helpers/fetchHelpers'
  import { notifier } from '@/helpers/notifier'

  document.title = 'Dev scripts'

  let pageType = 'count'
  let html = ''

  const load = async (type: any, params: any, title = '') => {
    pageType = type
    document.title = 'Dev scripts - ' + (title || type)
    const data = await fetchData('admin/dev', { params, type })
    if (data.error) {
      notifier.info(data.error)
    } else {
      if (data.html) {
        html = data.html
      }
    }
  }
</script>

<button type='button' on:click={() => load('wifTemplates', {})}>Wif templates HTML</button>
<button type='button' on:click={() => load('generateTranslationTable', {})}>Generate translation table</button>
<button type='button' on:click={() => load('fakeSynonyms', {})}>Fake synonyms</button>
<button type='button' on:click={() => load('renameEnglishWordQueries', {})}>Rename english word queries</button><br />
<button type='button' on:click={() => load('saveLanguagesStat', {})}>Save lang stat</button>
<button type='button' on:click={() => load('showAllLanguagesStat', {})}>Show all languages stat</button>
<button type='button' on:click={() => { pageType = 'showSingleStats'; html = '' }}>Show single stats</button>
<button type='button' on:click={() => load('showAllLanguagesSummary', {})}>Show all languages summary</button>
<button type='button' on:click={() => load('phpinfo', {})}>PHP info</button>
<hr />
{#if pageType === 'showSingleStats'}
  {#each languages as language}
    <button title={language.name} type='button' on:click={() => load(pageType, { language: language.id })}>{language.iso}</button>
  {/each}
{/if}
<div>{@html html}</div>
