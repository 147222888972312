<script lang='ts'>
  // todo - make page interactive, if we choose some option show documentation for that option only, and maybe include select list inside overlay where we can choose option for documentation (including show all)
</script>
<h1>CLI doc</h1>
<p>From dropdown select choose script you want to run</p>
<p>Possible options are:</p>
<ul>
  <li>Add language letters</li>
  <li>Cli update complexity</li>
  <li>Cli update sentences order</li>
  <li>Cli update words level</li>
  <li>Generate tests</li>
  <li>Insert example sentences</li>
  <li>Moderate audio sentences</li>
  <li>Moderate audio words</li>
  <li>Word forms</li>
</ul>
<p>For most of the scripts you can choose language they will be run for</p>
<h2>Add language letters</h2>
<p>For programmers to help adding language letters to the database</p>
<h2>Cli update complexity</h2>
<p>Update coplexity for sentences in the database</p>
<h2>Cli update sentences order</h2>
<p>Update sentence order in lessons.</p>
<h2>Cli update words level</h2>
<p>Update words level and frequency in the database</p>
<h2>Generate tests</h2>
<p>Generating tests for new languages </p>
<!-- todo - add more info about generating tests -->
<h2>Insert example sentences</h2>
<p>Insert example sentences for new languages. This is just temporary solution for testing, after this it would be great to choose sentences manually on the page for adding example sentences.</p>
<!-- todo - add link to page where we can choose example sentences -->
<h2>Moderate audio sentences</h2>
<!-- todo - populate this section -->
todo
<h2>Moderate audio words</h2>
<!-- todo - populate this section -->
todo
<h2>Word forms</h2>
<!-- todo - populate this section -->
todo
