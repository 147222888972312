<script lang='ts'>
  import { onMount } from 'svelte'

  import languages from '@/config/languages'

  import { getInterfaceLanguageId } from '@/helpers/apiCall'
  import { fetchData } from '@/helpers/fetchHelpers'
  import getParameterByName from '@/helpers/getParameterByName'
  import { notifier } from '@/helpers/notifier'
  import { removePostSyntax } from '@/helpers/removePostSyntax'

  import AudioPlayer from '@/components/AudioPlayer.svelte'
  import AudioRecorder from '@/components/AudioRecorder.svelte'
  import AddAudioSentenceDoc from '@/components/docs/mix/AddAudioSentenceDoc.svelte'
  import Documentation from '@/components/Documentation.svelte'
  import HiddenPagesBoundary from '@/components/HiddenPagesBoundary.svelte'
  import NavigationButtons from '@/components/NavigationButtons.svelte'
  import PageInfobox from '@/components/PageInfobox.svelte'
  import SwipeHandlers from '@/components/SwipeHandlers.svelte'

  document.title = 'Add audio sentence'

  export let iso = ''
  export let level = ''

  const language = languages.find((i) => i.iso === iso)

  const interfaceId = getInterfaceLanguageId()

  let page = parseInt(getParameterByName('page')) || 1
  let allSentences: any[] = []
  let currentSubmissions: any[] = []
  let pagesCount = 0
  let currentSentence: any = {}
  let hideRecorded = false
  let recordingState = ''
  let myAudio: any
  let recordId: number
  let recordedCount: number
  let areAllRecorded: boolean

  $: myAudio = currentSentence.my_audio
  $: recordId = currentSentence.id
  $: recordedCount = Object.values(allSentences).filter((item) => item.my_audio).length
  $: areAllRecorded = recordedCount === pagesCount

  const loadPage = async (all = false) => {
    return fetchData('mod/addAudioSentenceLoad', {
      all,
      id_sentence: page,
      level
    })
  }

  const loadSubmissions = () => {
    loadPage()
      .then((data) => {
        currentSubmissions = data.submissions
      })
      .catch((e) => {
        notifier.warning(e.message)
      })
  }

  const loadSentences = () => {
    loadPage(true)
      .then((data) => {
        const { originals, submissions } = data
        allSentences = originals
        pagesCount = originals.length
        currentSubmissions = submissions

        if (hideRecorded && page === 1 && allSentences[page - 1].my_audio) {
          handlePageChange('next')
        }

        currentSentence = originals[page - 1]
        window.history.replaceState({}, document.title, location.search.split('?')[0] + '?page=' + page)
      })
      .catch((e) => {
        notifier.warning(e.message)
      })
  }

  onMount(() => {
    const localSettings = localStorage.getItem('hideRecordedSentences')
    if (localSettings) hideRecorded = JSON.parse(localSettings)
  })

  const handleHideRecordedPages = () => {
    hideRecorded = !hideRecorded
    localStorage.setItem('hideRecordedSentences', JSON.stringify(hideRecorded))
  }

  const handlePageChange = (direction: 'prev'|'next') => {
    if (recordingState) {
      notifier.warning('Unable to change page during recording')
      return
    }

    let next
    if (direction === 'prev' && page > 1) {
      next = hideRecorded ? findPrevUnrecorded(page) : page - 1
    } else if (direction === 'next' && page < pagesCount) {
      next = hideRecorded ? findNextUnrecorded(page) : page + 1
    }

    if (next) {
      page = next
      currentSentence = allSentences[page - 1]
      loadSubmissions()
      window.history.replaceState({}, document.title, location.search.split('?')[0] + '?page=' + page)
    }
  }

  const findPrevUnrecorded = (current: number): number => {
    const prev = current - 1
    if (prev > 0) {
      return allSentences[prev - 1].my_audio ? findPrevUnrecorded(prev) : prev
    }

    notifier.warning('There is no next unrecorded page')
    return current
  }

  const findNextUnrecorded = (current: number): number => {
    const next = current + 1
    if (next <= pagesCount) {
      return allSentences[next - 1].my_audio ? findNextUnrecorded(next) : next
    }

    notifier.warning('There is no previous unrecorded page')
    return current
  }

  const publishRecording = (publish: any, idCorrection: any) => {
    fetchData('mod/addAudioSentencePublish', { idCorrection, publish })
      .then(() => {
        notifier.success('Saved')
      })
      .catch((e) => notifier.warning(e.message))
  }

  const handleOnDelete = () => {
    const confirm = window.confirm('Are you sure?')
    if (confirm) {
      fetchData('mod/addAudioSentenceDelete', { id: currentSentence.my_audio_id }).then(() => {
        currentSentence.my_audio = ''
        allSentences[page - 1].my_audio = ''
      })
    }
  }

  const onAfterSuccess = ({ path, my_audio_id }: any) => {
    recordingState = ''
    currentSentence.my_audio = path
    allSentences[page - 1].my_audio = path
    allSentences[page - 1].my_audio_id = my_audio_id
  }

  loadSentences()
  const levels = ['a1', 'a2', 'b1', 'b2', 'c1', 'c2'] // todo get max level first
</script>

<SwipeHandlers onDown={() => {}} onLeft={() => {}} onRight={() => {}} onUp={() => {}} />

<div class='audio-page'>
  <h1>Add audio for sentence</h1>
  <div class='choose-level'>
    {#each levels as tmpLevel}
      {#if level === tmpLevel}
        <span>{tmpLevel}</span>
      {:else}
        <!-- todo - find how to do this without reload (just replacing with Link won't work) -->
        <a href='/{iso}/mix/add-audio-sentence/{tmpLevel}'>{tmpLevel}</a>
      {/if}
    {/each}
  </div>

  <PageInfobox
    checked={hideRecorded}
    current={page}
    done={recordedCount}
    handleClickOnHide={handleHideRecordedPages}
    pageType='add-audio-sentence'
    total={pagesCount} />

  <HiddenPagesBoundary areAllFinished={areAllRecorded} hideFinished={hideRecorded} isCurrentFinished={!!currentSentence.my_audio} pageType='add-audio-sentence'>
    {#if myAudio}
      <div class='my-audio-box'>
        <h2>Submitted audio:</h2>
        <div class='audio-wrapper'>
          <AudioPlayer fullSrc={myAudio} mini />
        </div>
        <button class='default-button' type='button' on:click={handleOnDelete}>Delete</button>
      </div>
    {:else}
      <AudioRecorder
        changeState={(state) => { recordingState = state }}
        {onAfterSuccess}
        params={{ id: currentSentence.id, interfaceId, languageId: language?.id }}
        src={recordId?.toString()}
        url='/mod/addAudioSentenceUpload' />
    {/if}

    <div class='sentence-container'>
      <p>Language: {language?.name}</p>
      <hr />
      <p class='sentence'>{removePostSyntax(currentSentence.sentence)}</p>
      <hr />
    </div>

    {#if currentSubmissions && currentSubmissions.length > 0}
      <div class='submissions-box'>
        <h2>Submitted recordings</h2>
        {#each currentSubmissions as item}
          <div>
            <span class='audio-wrapper'>
              <AudioPlayer fullSrc={item.content} mini />
            </span>
            <button class='default-button' type='button' on:click={() => publishRecording('approve', item.id)}>Approve</button>
            <button class='default-button' type='button' on:click={() => publishRecording('reject', item.id)}>Reject</button>
            <button class='default-button' type='button' on:click={() => publishRecording('neutral', item.id)}>Can be better</button>
            <button class='default-button' type='button' on:click={() => publishRecording('publish', item.id)}>Publish</button>
          </div>
        {/each}
      </div>
    {/if}
  </HiddenPagesBoundary>

  {#if !areAllRecorded}
    <NavigationButtons current={page} onNext={() => handlePageChange('next')} onPrev={() => handlePageChange('prev')} total={pagesCount} />
  {/if}
</div>
<Documentation>
  <AddAudioSentenceDoc />
</Documentation>
<style lang='scss'>
  .sentence-container {
    max-width: 76.8rem;
    margin: 2rem auto 3.2rem;
    padding: 2rem;
    color: var(--white-text);
    background: var(--gray-2);

    > p {
      line-height: 1.5;
      color: var(--white-text);
    }

    > .sentence {
      margin: 0;
      font-size: 3.2rem;
    }
  }
</style>
