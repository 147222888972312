<script lang='ts'>
  import { onMount } from 'svelte'

  import { Func } from '@/definitions/definitions'

  export let onClick: Func
  export let hidden = true

  onMount(() => {
    const onKeyUp = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClick()
      }
    }

    document.addEventListener('keyup', onKeyUp)
    document.body.style.overflow = 'hidden'

    return () => {
      document.removeEventListener('keyup', onKeyUp)
      document.body.style.overflow = 'auto'
    }
  })

  const handleOnClick = (e: MouseEvent) => {
    if (e.target === e.currentTarget) {
      onClick()
    }
  }
</script>

<div
  class='overlay-wrapper'
  class:-hidden={hidden}
  role='button'
  tabindex='0'
  on:click={handleOnClick}
  on:keypress={() => {}}>
  <div
    class='close'
    role='button'
    tabindex='0'
    on:click={handleOnClick}
    on:keypress={() => {}}>X</div>
  <slot />
</div>

<style lang='scss'>
  .overlay-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
    width: 100vw;
    height: 100vh;
    background: rgba(99, 99, 99, 0.5);
    backdrop-filter: blur(0.4rem);

    &.-hidden {
      display: none;
    }

    > .close {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
  }
</style>
