<script lang='ts'>
  import { link } from 'svelte-routing'

  import { poses } from '@/helpers/data'
  import { fetchData } from '@/helpers/fetchHelpers'
  import getParameterByName from '@/helpers/getParameterByName'
  import { toInt } from '@/helpers/mix'
  import { notifier } from '@/helpers/notifier'

  import AddWordExamplesDoc from '@/components/docs/words/AddWordExamplesDoc.svelte'
  import Documentation from '@/components/Documentation.svelte'

  import { Word } from '@/definitions/definitions'

  document.title = 'Add word examples'

  const max = 4
  export let iso: string
  let word: Word = {} as Word
  let pagesCount = 0
  let sentences: any = []
  let choosen: any = {}
  let form: HTMLFormElement
  const all = location.href.indexOf('add-word-examples-all') > 0
  let currentExamples: any = {}

  let page = parseInt(getParameterByName('page')) || 1
  const loadPage = (pg: number) => {
    page = pg
    fetchData('mod/exampleSentencesLoad', {
      all,
      page: pg
    }).then((data) => {
      choosen = {}
      window.history.replaceState({}, document.title, location.search.split('?')[0] + '?page=' + pg)
      word = data.word
      pagesCount = data.pagesCount
      sentences = data.sentences
      currentExamples = data.currentExamples
      if (Object.keys(data.currentExamples).length) {
        choosen = currentExamples
        for (const i in choosen) {
          check(i, choosen[i], null)
        }
      } else {
        choosen = {}
      }
    })
  }

  const saveExampleSentences = () => {
    if (Object.keys(choosen).length === 0) {
      notifier.warning('Choose sentences you want to save first')
      return
    }
    fetchData('mod/exampleSentencesSave', {
      idWord: word.id,
      sentences: choosen
    }).then(() => {
      form.reset()
      choosen = {}
      notifier.warning('Saved!')
      if (page < pagesCount) {
        loadPage(page + 1)
      }
    })
  }

  const check = (sentence: any, val: any, e: any) => {
    const length = Object.values(choosen).length
    if (e && length >= max && val > 0 && typeof choosen[sentence.id] === 'undefined') {
      notifier.warning('Maximum allowed sentences is ' + max)
      e.preventDefault()
    } else {
      if (toInt(val) === 0) {
        if (choosen[sentence.id]) delete choosen[sentence.id]
      } else {
        if (sentence.id) choosen[sentence.id] = val
      }
    }
  }

  loadPage(page)

  function formSubmit () {
  // todo - implement
  }
</script>

<div class='example-sentences-page'>
  {#if all}
    <a href='/{iso}/words/add-word-examples' use:link>Show only not rated</a>
  {:else}<a href='/{iso}/words/add-word-examples-all' use:link>Show all</a>{/if}
  <form bind:this={form} method='post' on:submit|preventDefault={formSubmit}>
    <div class='word-info'>
      <div style='border-bottom: 1px solid white;'>Word <strong>{word.word}</strong> {word.id}</div>
      {poses[word.pos]}
      <span class='label'>Definition:</span>
      <p>{word.definition}</p>
    </div>

    <p class='sub-title'><strong>Choose best sentences for this word, you can add up to 4 sentences, use 1 for the best, 2 for less good...</strong></p>
    <button type='button' on:click={saveExampleSentences}>Send</button>
    <div class='prev-next-flex'>
      {#if page > 1}<button class='button-prev' type='button' on:click={() => loadPage(page - 1)}>Prev</button>{/if}
      <div>
        <table>
          <thead>
            <tr>
              <th />
              <th>0</th>
              <th>1</th>
              <th>2</th>
              <th>3</th>
              <th>4</th>
              <th>Example Sentences</th>
            </tr>
          </thead>
          {#each sentences as sentence}
            <tr>
              <td>{sentence.id}</td>
              <td><label>Reset<input class='reset' type='radio' on:click={(e) => check(sentence, 0, e)} name='gr_{sentence.id}' /></label></td>
              <td>
                <label><input checked={choosen[sentence.id] === 1} type='radio' on:click={(e) => check(sentence, 1, e)} name='gr_{sentence.id}' /></label>
              </td>
              <td>
                <label><input checked={choosen[sentence.id] === 2} type='radio' on:click={(e) => check(sentence, 2, e)} name='gr_{sentence.id}' /></label>
              </td>
              <td>
                <label><input checked={choosen[sentence.id] === 3} type='radio' on:click={(e) => check(sentence, 3, e)} name='gr_{sentence.id}' /></label>
              </td>
              <td>
                <label><input checked={choosen[sentence.id] === 4} type='radio' on:click={(e) => check(sentence, 4, e)} name='gr_{sentence.id}' /></label>
              </td>
              <td>{sentence.sentence}</td>
            </tr>
          {/each}
        </table>
      </div>
      {#if page < pagesCount}<button class='button-next' type='button' on:click={() => loadPage(page + 1)}>Next</button>{/if}
    </div>
  </form>
</div>
<Documentation>
  <AddWordExamplesDoc />
</Documentation>
<style lang='scss'>
  .prev-next-flex {
    position: relative;
  }

  .example-sentences-page {
    max-width: 90rem;
    margin: auto;
  }

  td > label {
    display: inline-block;
    padding: 0.2rem 1rem;

    &:hover {
      background: var(--yellow-1);
    }

    > input {
      margin: 0;

      &.reset {
        visibility: hidden;
      }
    }
  }

  .word-info {
    min-height: 9.6rem;
  }

  .button-prev,
  .button-next {
    position: absolute;
    top: 4.8rem;
    left: -9rem;
  }

  .button-next {
    right: -9rem;
    left: inherit;
  }
</style>
