<script lang="ts">
  import Icon from '@/components/Icon.svelte'

  export let column: string = ''
  export let table: string = ''
  export let value: string = ''
</script>
{#if ['mod-sentence-audio', 'mod-word-audio'].includes(table) && column === 'content'}
  <a href={value} rel='noopener noreferrer' target='_blank'>
    <Icon icon='Play' size={16} weight='fill' />
  </a>
{/if}
