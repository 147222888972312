<script lang='ts'>
  // Used just for non-english
  // todo - add lesson chooser
  import { fetchData } from '@/helpers/fetchHelpers'
  import getParameterByName from '@/helpers/getParameterByName'
  import { notifier } from '@/helpers/notifier'

  import LessonTranslateDoc from '@/components/docs/lesson/LessonTranslateDoc.svelte'
  import Documentation from '@/components/Documentation.svelte'

  document.title = 'Translate lesson'

  export let iso = ''
  let page = parseInt(getParameterByName('page')) || 1
  let sentences: any[] = []
  let pagesCount = 0
  let answers: any = {}
  let givenAnswers: any = {}
  let lessons: any[] = []
  let myLessons: any = {}
  let lessonChooser: any
  let lessonsOrder: any[] = []

  const loadPage = (pg: any, markLesson = true) => {
    page = pg
    fetchData('mod/lessonTranslate', {
      page: pg
    }).then((data) => {
      window.history.replaceState({}, document.title, location.search.split('?')[0] + '?page=' + pg)
      sentences = data.sentences
      pagesCount = data.pagesCount
      givenAnswers = data.mySentences
      answers = data.mySentences
      myLessons = data.myLessons
      lessonsOrder = data.lessonsOrder

      if (data.lessons) {
        lessons = Object.values(data.lessons.sort((a: any, b: any) => b.name < a.name))
      }
      if (markLesson) {
        setTimeout(() => {
          if (lessonChooser) lessonChooser.value = data.lesson
        }, 200)
      }
    })
  }
  loadPage(page)

  const savePages = () => {
    const ga: any = {}
    for (const i in givenAnswers) {
      if (givenAnswers[i]) {
        ga[i] = givenAnswers[i]
      }
    }
    fetchData('mod/lessonTranslateSave', {
      answers: ga
    }).then(() => {
      notifier.info('Saved')
    })
  }
  const updateAnswer = (e: any, id: number) => {
    givenAnswers[id] = e.target.value
  }

  const changeLesson = () => {
    const index = lessonsOrder.indexOf(parseInt(lessonChooser.value)) + 1
    if (index > 0) loadPage(index, false)
    else {
      sentences = []
    }
  }
</script>

<div>
  {#if iso === 'eng'}
    This page is available just for languages other than English
  {:else if lessons.length}

    <div class='custom-select'>
      <select bind:this={lessonChooser} on:change={changeLesson} name='lessons'>
        <option value=''>Choose lesson</option>
        {#each lessons as lesson (lesson.id)}
          <option value={lesson.id}>{lesson.name}{myLessons[lesson.id] > 0 ? ' ==Edited' : ''}</option>
        {/each}
      </select>
    </div>
    {#if sentences.length}
      <div class='prevNextFlex'>
        {#if page > 1}
          <button type='button' on:click={() => loadPage(page - 1)}>Prev</button>
        {/if}
        <div>
          <table>
            <tr>
              <th>Original</th>
              <th>Translation</th>
            </tr>
            {#each sentences as sentence}
              <tr>
                <td>{sentence.content}</td>
                <td class='edit-area'>
                  <textarea cols='20' rows='1' on:change={(e) => updateAnswer(e, sentence.id)} bind:value={answers[sentence.id]} name='answer[]' />
                </td>
              </tr>
            {/each}
            <tr>
              <td colspan='2'>
                <button type='button' on:click={savePages}>Save</button>
              </td>
            </tr>
          </table>
        </div>

        {#if page < pagesCount}
          <button type='button' on:click={() => loadPage(page + 1)}>Next</button>
        {/if}
      </div>
    {:else}Choose lesson
    {/if}
  {:else}No lessons for translation in this language
  {/if}
</div>
<Documentation>
  <LessonTranslateDoc />
</Documentation>
<style lang='scss'>
  td {
    width: 50%;
    padding: 0.2rem 1rem;
  }

  textarea {
    width: 100%;
  }
</style>
