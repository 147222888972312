<script lang='ts'>
  import languages from '@/config/languages'

  import { fetchData } from '@/helpers/fetchHelpers'
  import { notifier } from '@/helpers/notifier'

  import AudioPlayer from '@/components/AudioPlayer.svelte'
  import AudioRecorder from '@/components/AudioRecorder.svelte'
  import CreateReadingTutorialDoc from '@/components/docs/ipa/CreateReadingTutorialDoc.svelte'
  import Documentation from '@/components/Documentation.svelte'

  document.title = 'Create Reading Tutorial'

  export let iso: string = ''

  let levels: string[] = []
  let levelTypes: Record<number, 'lowercase' | 'uppercase'> = {}
  const slots: any[] = []
  let level = 1 // current level
  let activeSlot: any = {}
  // eslint-disable-next-line no-unused-vars
  let recordingState = ''

  const question = {
    content: '',
    ipa: '',
    slug: '',
    type: 'learning'
  }

  const language = languages.find((i) => i.iso === iso)

  load(1)

  // todo - replace hardcoded host value
  // todo - add validation to prevent writing lowercase letters on uppercase test
  function load(lvl: number) {
    fetchData('read/createLoad', { level: lvl }).then((data) => {
      if (data.error) {
        notifier.error(data.error)
      } else {
        level = data.level
        levels = Object.keys(data.levels)
        levelTypes = data.levels
        for (let i = 0; i < 50; i++) {
          slots[i] = { index: i }
        }
        for (let i = 0; i < data.slots.length; i++) {
          slots[i] = data.slots[i]
          slots[i].index = i
        }
      }
    })
  }

  const addLevel = () => {
    const variant = confirm('Is this uppercase level?') ? 'uppercase' : 'lowercase'
    fetchData('read/createAddLevel', { variant }).then((data) => {
      if (data.error) {
        notifier.error(data.error)
      } else {
        levels = Object.keys(data.levels)
        levelTypes = data.levels
      }
    })
  }
  // eslint-disable-next-line no-unused-vars
  const removeLevel = () => {
    fetchData('read/createRemoveLevel', {}).then((data) => {
      if (data.error) {
        notifier.error(data.error)
      } else {
        // eslint-disable-next-line no-console
        console.log('remove', data)
      }
    })
  }
  const saveQuestion = (e: any) => {
    e.preventDefault()
    fetchData('read/createSaveQuestion', {
      ...question,
      level,
      slot: activeSlot.index
    }).then((data) => {
      if (data.error) {
        notifier.error(data.error)
      } else {
        slots[activeSlot.index] = data
        e.target.reset()
        activeSlot = {}
      }
    })
  }
  const onAfterSuccess = (data: any) => {
    recordingState = ''
    slots[activeSlot.index].audio = data.path
  }

  const setSlot = (index: number) => {
    activeSlot = slots[index]
    question.content = activeSlot.content || ''
    question.ipa = activeSlot.ipa || ''
    question.slug = activeSlot.slug || ''
  }
</script>

{#each levels as levelTab, index}
  <div
    class='level-link'
    class:-active={index + 1 === level}
    role='button'
    tabindex='0'
    on:keypress={() => {}}
    on:click={() => load(index + 1)}>Level {index + 1}</div>
{/each}
<button type='button' on:click={addLevel}>+</button>

<h3>Level: {level} {levelTypes[level]}</h3>
<form id='form2' action='' />
{#if typeof activeSlot.index !== 'undefined'}
  <form action='' on:submit={saveQuestion}>
    <p>Slot: {activeSlot.index + 1}</p>
    <p>
      <label for='content'>Text</label>
      <input id='content' type='text' bind:value={question.content} name='content' />
    </p>
    <p>
      <AudioRecorder
        changeState={(state) => (recordingState = state)}
        {onAfterSuccess}
        params={{ languageId: language?.id, level, slot: activeSlot.index }}
        reload
        src={level + '_' + activeSlot.index}
        url='/read/createSaveAudio'
      />
      {#if activeSlot.audio}
        <audio controls src={activeSlot.audio} />
      {/if}
    </p>
    <p>
      <label for='ipa'>ipa</label>
      <input id='ipa' type='text' bind:value={question.ipa} name='ipa' />
    </p>
    <div>
      <label for='type'>type</label>
      <div class='custom-select'>
        <select id='type' bind:value={question.type} name='type'>
          <option value='both'>Both</option>
          <option value='learning'>Learning</option>
          <option value='test'>Test</option>
        </select>
      </div>
    </div>
    <p>
      <label for='slug'>identifier</label>
      <input id='slug' type='text' bind:value={question.slug} name='slug' />
      <small>If you want to group multiple assignments into one you can give them name here, anything maximum 20 letters long, it won't be shown in
        the interface just will be used for grouping.</small>
    </p>
    <input type='submit' value='Save' />
    <input type='reset' value='Cancel' on:click={() => { activeSlot = {} }} />
  </form>
{/if}

{#each slots as slot, index}
  <div
    class={`single-slot -${slot.question_type || 'empty'}`}
    class:-active={activeSlot.index === slot.index}
    role='button'
    tabindex='0'
    on:keypress={() => {}}
    on:click={() => setSlot(index)}>
    <span class='index'>{slot.index + 1}</span>
    {slot.content || ''}
    {#if slot.audio}
      <AudioPlayer fullSrc={slot.audio} mini />
    {/if}
    <small class='slug'>{slot.slug || ''}</small>
  </div>
{/each}
<Documentation>
  <CreateReadingTutorialDoc />
</Documentation>
<style lang='scss'>
  .level-link,
  .single-slot {
    display: inline-block;
    padding: 0.4rem 1rem;
    border: solid 1px var(--gray-2);

    &.-active {
      background: var(--blue-3);
    }
  }

  .single-slot {
    position: relative;
    min-width: 9.6rem;
    min-height: 3.6rem;

    &.-both {
      background: var(--blue-1);
    }

    &.-test {
      background: var(--orange-1);
    }

    &.-learning {
      background: var(--green-1);
    }

    > .index {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0.2rem;
      color: var(--gray-6);
    }

    > .slug {
      color: var(--gray-6);
    }
  }

  label {
    display: inline-block;
    min-width: 7.2rem;
    margin-right: 1rem;
    text-align: right;
  }
</style>
