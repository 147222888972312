<script lang='ts'>
  export let data: Record<string, string>[] = [],
    columns = {}

  const cols = Object.keys(columns)
</script>
{#if cols.length && data.length}
  <table class='table'>
    <thead>
      <tr>
        {#each Object.values(columns) as column}
          <th>{column}</th>
        {/each}
      </tr>
    </thead>
    <tbody>
      {#each data as row, k}
        <tr>
          {#each cols as col}
            <td>{row[col]}</td>
          {/each}
        </tr>
      {/each}
    </tbody>
  </table>
{:else}
  <p>No data</p>
{/if}
