export const levels = ['A1', 'A2', 'B1', 'B2', 'C1', 'C2']
export const batches = ['manual', '150', '500', '1500', '5000', '20000']
export const poses = [
  { id: 0, pos: undefined },
  { id: 1, pos: 'noun' },
  { id: 2, pos: 'verb' },
  { id: 3, pos: 'adjective' },
  { id: 4, pos: 'pronoun' },
  { id: 5, pos: 'adverb' },
  { id: 6, pos: 'number' },
  { id: 7, pos: 'preposition' },
  { id: 8, pos: 'conjunction' },
  { id: 9, pos: 'interjection' },
  { id: 10, pos: 'particle' },
  { id: 11, pos: 'article' },
  { id: 12, pos: 'unknown' }
]
