<script lang='ts'>
  export let areAllFinished: boolean, hideFinished: boolean, isCurrentFinished: boolean, pageType: string

  const hideCurrent = isCurrentFinished
  const message = (() => {
    switch (pageType) {
      case 'translate-word':
        return {
          all: 'You\'ve translated all the words. If you want to see translated words, uncheck the "Hide translated pages".',
          current:
            'You have hidden translated pages, uncheck "Hide translated pages" if you want to see this page or click "Prev" or "Next" to change the page.'
        }
      case 'add-audio-word':
        return {
          all: 'You\'ve recorded all the words. If you want to see recorded words, uncheck the "Hide recorded pages".',
          current: 'You have hidden recorded pages, uncheck "Hide recorded pages" if you want to see this page or click "Prev" or "Next" to change the page.'
        }
      case 'add-audio-sentence':
        return {
          all: 'You\'ve recorded all the sentences. If you want to see recorded sentences, uncheck the "Hide recorded pages".',
          current: 'You have hidden recorded pages, uncheck "Hide recorded pages" if you want to see this page or click "Prev" or "Next" to change the page.'
        }
      default:
        return {
          all: 'hidden',
          current: 'hidden'
        }
    }
  })()
</script>

{#if areAllFinished && hideFinished}
  <div class='notification-box'>{message.all}</div>
{:else if hideCurrent && hideFinished}
  <div class='notification-box'>{message.current}</div>
{:else}
  <slot />
{/if}
