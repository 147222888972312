<script lang='ts'>
  import { onDestroy } from 'svelte'
  import { link, useLocation } from 'svelte-routing'

  import languages from '@/config/languages'

  import { fetchData } from '@/helpers/fetchHelpers'
  import { navigateWithReset } from '@/helpers/navigateWithReset'
  import storage from '@/helpers/storage'

  export let logged = false
  let userData: any = {}
  let iso = ''

  $: if (userData) {
    storage.write('user', userData)
  }

  if (logged) {
    refreshMenuData()
    setInterval(refreshMenuData, 1e5) // every 100 seconds
  }

  function refreshMenuData() {
    fetchData('main/menu', {})
      .then((data) => {
        userData = data
        storage.write('user', userData)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  function goToSamePageForOtherLanguage() {
    if (iso) {
      const segments = location.pathname.split('/')
      segments[1] = iso
      navigateWithReset(segments.join('/'))
    }
  }

  const locationStore = useLocation()
  const unsubscribe = locationStore.subscribe(routerData => {
    const segments = routerData.pathname.split('/')
    iso = segments[1]
    if (iso?.length !== 3) iso = ''
  })

  onDestroy(() => {
    unsubscribe()
  })
</script>

<header class='main-header'>
  <div class='wrapper'>
    <a class='logo-wrapper' href='/' use:link><img alt='Langoid Logo' src='/images/logo.svg' /><span>Langoid moderator</span></a>
    {#if logged}
      {#if iso}
        <div class='custom-select'>
          <select bind:value={iso} on:change={goToSamePageForOtherLanguage} name=''>
            {#each languages as lang}
              <option value={lang.iso}>{lang.name}</option>
            {/each}
          </select>
        </div>
      {/if}
      {#if userData}
        <div class='user-stats'>
          <div class='level' title='Admin level'>
            <span>🏆 {userData.is_admin}</span>
          </div>
          <span class='nav-item -avatar'>
            {#if userData.avatar}
              <img alt='profile' src={userData.avatar} width='40' />
            {/if}
          </span>
        </div>
      {/if}
    {:else}
      <div class='user-stats'>
        {#if location.href.indexOf('langoid.com') > -1}
          <a class='nav-item' href='https://langoid.com/login'>Login</a>
        {:else}
          <a class='nav-item' href='/login'>Login</a>
        {/if}
      </div>
    {/if}
  </div>
</header>
<style lang='scss'>
  .logo-wrapper {
    display: flex;
    gap: 1rem;
    align-items: center;
    align-self: center;
    margin: 0 auto 0 0;
    cursor: pointer;

    > img {
      align-self: center;
    }
  }

  .main-header {
    background: var(--white-1);
    border-top: 0.4rem solid var(--red-3);
    box-shadow: var(--light-box) -shadow;

    > .wrapper {
      display: flex;
      justify-content: space-between;
      max-width: 120rem;
      margin: 0 auto;
      padding: 0 3.2rem;
    }
  }

  .nav-item {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-right: 1.8rem;
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 1;

    > img {
      margin-left: 1.2rem;
    }

    &.-avatar > img {
      border-radius: 50%;
    }
  }

  .user-stats {
    display: flex;
    margin: 0 1rem;

    > div {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-right: 1.8rem;
      font-weight: bold;
      font-size: 1.8rem;
    }
  }

  @media (max-width: 768px) {
    img {
      max-width: 3.2rem;
    }
  }
</style>
