const storage = {
  read: (key: string, defaultValue = {}) => {
    try {
      const item = localStorage.getItem(key)
      if (item === null) return defaultValue
      else return JSON.parse(item)
    } catch (e) {
      console.error(e)
    }
  },
  update: (key: string, element: string, newValue: any, defaultValue = {}) => {
    try {
      const item = localStorage.getItem(key)
      const tmp = item === null ? defaultValue : JSON.parse(item)
      tmp[element] = newValue
      localStorage.setItem(key, JSON.stringify(tmp))
    } catch (e) {
      console.error(e)
    }
  },
  write: (key: string, val: any, defaultValue = {}) => {
    if (typeof defaultValue === 'object') localStorage.setItem(key, JSON.stringify(val))
    else localStorage.setItem(key, val)
  }
}
export default storage
