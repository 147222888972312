<script lang='ts'>
  import languages from '@/config/languages'

  import { poses } from '@/helpers/data'
  import { fetchData } from '@/helpers/fetchHelpers'
  import { notifier } from '@/helpers/notifier'

  export let iso = ''
  let pos: number, language: number, action: any, html = ''

  document.title = 'Word forms ' + iso

  const load = async () => {
    const data = await fetchData('admin/wordForms', {
      action,
      language: iso ? languages.filter(el => el.iso === iso)[0].id : language,
      pos
    })
    if (data.error) {
      notifier.info(data.error)
    } else {
      if (data.html) {
        html = data.html.replace(/@@\.([a-z0-9A-Z]+)/g, (match: any, group: any) => group)
          .replace(/{{[^}]+}}/g, '-')
      }
    }
  }
</script>
<form action='' method='get' on:submit|preventDefault={load}>
  <p><label for='action'>Words separated by commas</label><br />
    <textarea id='a' cols='50' required rows='5' bind:value={action} />
  </p>
  <div><label for='vrsta_reci'>Part of speech</label>
    <div class='custom-select'>
      <select id='vrsta_reci' required bind:value={pos}>
        <option value=''>Choose part of speech</option>
        {#each poses as pos, k}
          {#if k > 0}
            <option value={k}>{pos.pos}</option>
          {/if}
        {/each}
      </select>
    </div>
    {#if !iso}
      <div class='custom-select'>
        <select id='jezik' required bind:value={language}>
          <option value=''>Choose language</option>
          {#each languages as lang}
            <option value={lang.id}>{lang.name} ({lang.iso})</option>
          {/each}
        </select>
      </div>
    {/if}
  </div>
  <input type='submit' value='Send' />
</form>
<div>
  {@html html}
</div>
