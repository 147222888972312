<script lang='ts'>
  import { onMount } from 'svelte'

  import languages from '@/config/languages'

  import { getInterfaceLanguageId } from '@/helpers/apiCall'
  import { fetchData } from '@/helpers/fetchHelpers'
  import getParameterByName from '@/helpers/getParameterByName'
  import { notifier } from '@/helpers/notifier'

  import AudioPlayer from '@/components/AudioPlayer.svelte'
  import AudioRecorder from '@/components/AudioRecorder.svelte'
  import AddAudioWordDoc from '@/components/docs/mix/AddAudioWordDoc.svelte'
  import Documentation from '@/components/Documentation.svelte'
  import HiddenPagesBoundary from '@/components/HiddenPagesBoundary.svelte'
  import NavigationButtons from '@/components/NavigationButtons.svelte'
  import PageInfobox from '@/components/PageInfobox.svelte'
  import SwipeHandlers from '@/components/SwipeHandlers.svelte'
  import WordAudioContainer from '@/components/WordAudioContainer.svelte'

  document.title = 'Add audio word'

  export let iso = ''
  export let level = 'a1'
  const language = languages.find((i) => i.iso === iso)

  const interfaceId = getInterfaceLanguageId()

  let words: any = []
  let submissions: any = []
  let page = parseInt(getParameterByName('page')) || 1
  let pagesCount = 0
  let currentWord: any = {}
  let recordingState = ''
  let hideRecorded = false

  $: myAudio = currentWord.my_audio
  $: recordId = currentWord.id
  $: recordedCount = Object.values(words).filter((item: any) => item.my_audio).length
  $: areAllRecorded = recordedCount === pagesCount
  $: currentSubmissions = submissions[currentWord.id]

  onMount(() => {
    const localSettings = localStorage.getItem('hideRecordedWords')
    if (localSettings) hideRecorded = JSON.parse(localSettings)
  })

  const loadWords = () => {
    fetchData('mod/addAudioWordLoad', {
      all: true,
      level,
      page
    })
      .then((data) => {
        pagesCount = data.originals.length
        words = data.originals
        submissions = data.submissions

        if (hideRecorded && page === 1 && words[page - 1].my_audio) {
          handlePageChange('next')
        }

        currentWord = data.originals[page - 1]
        currentWord.english = {
          definition: currentWord.englishDefinition || '',
          word: currentWord.englishId || 0
        }
        window.history.replaceState({}, document.title, location.search.split('?')[0] + '?page=' + page)
      })
      .catch((e) => {
        notifier.warning(e.message)
      })
  }

  const handlePageChange = (direction: 'prev' | 'next') => {
    if (recordingState) {
      notifier.warning('Unable to change page during recording')
      return
    }

    let next
    if (direction === 'prev' && page > 1) {
      next = hideRecorded ? findPrevUnrecorded(page) : page - 1
    } else if (direction === 'next' && page < pagesCount) {
      next = hideRecorded ? findNextUnrecorded(page) : page + 1
    }

    if (next) {
      page = next
      currentWord = words[page - 1]
      window.history.replaceState({}, document.title, location.search.split('?')[0] + '?page=' + page)
    }
  }

  const findPrevUnrecorded = (current: number): number|void => {
    const prev = current - 1
    if (prev > 0) {
      return words[prev - 1].my_audio ? findPrevUnrecorded(prev) : prev
    }

    notifier.warning('There is no next unrecorded page')
  }

  const findNextUnrecorded = (current: number): number|void => {
    const next = current + 1
    if (next <= pagesCount) {
      return words[next - 1].my_audio ? findNextUnrecorded(next) : next
    }

    notifier.warning('There is no previous unrecorded page')
  }

  // eslint-disable-next-line camelcase
  const onAfterSuccess = ({ path, my_audio_id }: any) => {
    recordingState = ''
    currentWord.my_audio = path
    words[page - 1].my_audio = path
    // eslint-disable-next-line camelcase
    words[page - 1].my_audio_id = my_audio_id
  }

  const handleOnDelete = () => {
    const confirm = window.confirm('Are you sure?')
    if (confirm) {
      fetchData('mod/addAudioWordDelete', { id: currentWord.my_audio_id }).then(() => {
        currentWord.my_audio = ''
        words[page - 1].my_audio = ''
      })
    }
  }

  const handleHideRecordedPages = () => {
    hideRecorded = !hideRecorded
    localStorage.setItem('hideRecordedWords', JSON.stringify(hideRecorded))
  }

  const publishRecording = (publish: 'approve'|'reject'| 'neutral'|'publish', idCorrection: number) => {
    fetchData('mod/addAudioWordPublish', { idCorrection, publish })
      .then(() => {
        notifier.success('Saved')
      })
      .catch((e) => notifier.warning(e.message))
  }

  loadWords()
  const levels = ['a1', 'a2', 'b1', 'b2', 'c1', 'c2'] // todo get max level first
</script>

<SwipeHandlers onDown={() => {}} onLeft={() => handlePageChange('prev')} onRight={() => handlePageChange('next')} onUp={() => {}} />

<div class='audio-page'>
  <h1>Add audio for word</h1>
  <div class='choose-level'>
    {#each levels as tmpLevel}
      {#if level === tmpLevel}
        <span>{tmpLevel}</span>
      {:else}
        <!-- todo - find how to do this without reload (just replacing with Link won't work) -->
        <a href='/{iso}/mix/add-audio-word/{tmpLevel}'>{tmpLevel}</a>
      {/if}
    {/each}
  </div>

  <PageInfobox
    checked={hideRecorded}
    current={page}
    done={recordedCount}
    handleClickOnHide={handleHideRecordedPages}
    pageType='add-audio-word'
    total={pagesCount} />

  <HiddenPagesBoundary areAllFinished={areAllRecorded} hideFinished={hideRecorded} isCurrentFinished={!!currentWord.my_audio} pageType='add-audio-word'>
    {#if myAudio}
      <div class='my-audio-box'>
        <h2>Submitted audio:</h2>
        <div class='audio-wrapper'>
          <AudioPlayer fullSrc={myAudio} mini />
        </div>
        <button class='default-button' type='button' on:click={handleOnDelete}>Delete</button>
      </div>
    {:else}
      <AudioRecorder
        changeState={(state) => { recordingState = state }}
        {onAfterSuccess}
        params={{ id: currentWord.id, interfaceId, languageId: language?.id }}
        src={recordId}
        url='/mod/addAudioWordUpload' />
    {/if}

    <WordAudioContainer {currentWord} {iso} />

    {#if currentSubmissions && currentSubmissions.length > 0}
      <div class='submissions-box'>
        <h2>Submitted recordings</h2>
        {#each currentSubmissions as item}
          <div>
            <span class='audio-wrapper'>
              <AudioPlayer fullSrc={item.content} mini />
            </span>
            <button class='default-button' type='button' on:click={() => publishRecording('approve', item.id)}>Approve</button>
            <button class='default-button' type='button' on:click={() => publishRecording('reject', item.id)}>Reject</button>
            <button class='default-button' type='button' on:click={() => publishRecording('neutral', item.id)}>Can be better</button>
            <button class='default-button' type='button' on:click={() => publishRecording('publish', item.id)}>Publish</button>
          </div>
        {/each}
      </div>
    {/if}
  </HiddenPagesBoundary>

  {#if !areAllRecorded}
    <NavigationButtons current={page} onNext={() => handlePageChange('next')} onPrev={() => handlePageChange('prev')} total={pagesCount} />
  {/if}
</div>
<Documentation>
  <AddAudioWordDoc />
</Documentation>
