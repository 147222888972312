const languages = [
  { id: 1, iso: 'eng', name: 'English', status: 1 },
  { id: 2, iso: 'srp', name: 'Serbian', status: 1 },
  { id: 3, iso: 'ceb', name: 'Cebuano', status: 1 },
  { id: 4, iso: 'tgl', name: 'Tagalog', status: 2 },
  { id: 5, iso: 'spa', name: 'Spanish', status: 2 },
  { id: 6, iso: 'cmn', name: 'Chinese', status: 2 },
  { id: 7, iso: 'jpn', name: 'Japanese', status: 1 },
  { id: 8, iso: 'kor', name: 'Korean', status: 1 },
  { id: 9, iso: 'deu', name: 'German', status: 1 },
  { id: 10, iso: 'fra', name: 'French', status: 1 },
  { id: 11, iso: 'ita', name: 'Italian', status: 1 },
  { id: 12, iso: 'rus', name: 'Russian', status: 2 },
  { dir: 'rtl', id: 13, iso: 'arb', name: 'Arabic', status: 2 },
  { id: 14, iso: 'ben', name: 'Bengali', status: 1 },
  { id: 15, iso: 'hin', name: 'Hindi', status: 1 },
  { id: 16, iso: 'ell', name: 'Greek', status: 1 },
  { id: 17, iso: 'por', name: 'Portuguese', status: 1 },
  { id: 18, iso: 'vie', name: 'Vietnamese', status: 0 },
  { id: 19, iso: 'tur', name: 'Turkish', status: 0 },
  { id: 20, iso: 'ind', name: 'Indonesian', status: 2 },
  { id: 33, iso: 'guj', name: 'Gujarati', status: 2 },
  { id: 78, iso: 'hye', name: 'Armenian', status: 1 },


  { id: 21, iso: 'pol', name: 'Polish', status: 1 },
  { id: 24, iso: 'bul', name: 'Bulgarian', status: 1 },
  { id: 41, iso: 'ukr', name: 'Ukrainian', status: 1 },
  { id: 55, iso: 'ces', name: 'Czech', status: 1 },
  { id: 71, iso: 'slk', name: 'Slovak', status: 1 },
  { id: 84, iso: 'bel', name: 'Belarusian', status: 1 },
  { id: 85, iso: 'slv', name: 'Slovenian', status: 1 },
  { id: 86, iso: 'mkd', name: 'Macedonian', status: 1 }
  /*

  { id: 22, iso: 'bos', name: 'Bosnian', status: 0 },
  { id: 23, iso: 'hrv', name: 'Croatian', status: 0 },
  { id: 25, iso: 'hun', name: 'Hungarian', status: 0 },
  { id: 26, iso: 'fin', name: 'Finish', status: 0 },
  { id: 27, iso: 'ron', name: 'Romanian', status: 0 },
  { id: 28, iso: 'jav', name: 'Javanese', status: 0 },
  { id: 29, iso: 'tel', name: 'Telugu', status: 0 },
  { id: 30, iso: 'mar', name: 'Marathi', status: 0 },
  { id: 31, iso: 'tam', name: 'Tamil', status: 0 },
  { id: 32, iso: 'pnb', name: 'Western Panjabi', status: 0 },
  { id: 34, iso: 'pes', name: 'Iranian Persian', status: 0 },
  { id: 35, iso: 'bho', name: 'Bhojpuri', status: 0 },
  { id: 36, iso: 'kan', name: 'Kannada', status: 0 },
  { id: 37, iso: 'sun', name: 'Sundanese', status: 0 },
  { id: 38, iso: 'mal', name: 'Malayalam', status: 0 },
  { id: 39, iso: 'ory', name: 'Oriya', status: 0 },
  { id: 40, iso: 'mya', name: 'Burmese', status: 0 },
  { id: 42, iso: 'pan', name: 'Panjabi', status: 0 },
  { id: 43, iso: 'amh', name: 'Amharic', status: 0 },
  { id: 44, iso: 'yor', name: 'Yoruba', status: 0 },
  { id: 47, iso: 'tha', name: 'Thai', status: 0 },
  { id: 48, iso: 'uzn', name: 'Northern Uzbek', status: 0 },
  { id: 49, iso: 'nld', name: 'Dutch', status: 0 },
  { id: 50, iso: 'sin', name: 'Sinhala', status: 0 },
  { id: 51, iso: 'swh', name: 'Swahili', status: 0 },
  { id: 52, iso: 'khm', name: 'Khmer', status: 0 },
  { id: 53, iso: 'npi', name: 'Nepali', status: 0 },
  { id: 54, iso: 'zul', name: 'Zulu', status: 0 },
  { id: 56, iso: 'urd', name: 'Urdu', status: 0 },
  { id: 57, iso: 'run', name: 'Rundi', status: 0 },
  { id: 58, iso: 'swe', name: 'Swedish', status: 0 },
  { id: 59, iso: 'xho', name: 'Xhosa', status: 0 },
  { id: 60, iso: 'prs', name: 'Dari', status: 0 },
  { id: 61, iso: 'plt', name: 'Malagasy', status: 0 },
  { id: 62, iso: 'hat', name: 'Haitian', status: 0 },
  { id: 63, iso: 'afr', name: 'Afrikaans', status: 0 },
  { id: 64, iso: 'kin', name: 'Kinyarwanda', status: 0 },
  { id: 65, iso: 'som', name: 'Somali', status: 0 },
  { id: 66, iso: 'azj', name: 'North Azerbaijani', status: 0 },
  { id: 67, iso: 'pbt', name: 'Southern Pashto', status: 0 },
  { id: 68, iso: 'dan', name: 'Danish', status: 0 },
  { id: 69, iso: 'kaz', name: 'Kazakh', status: 0 },
  { id: 70, iso: 'heb', name: 'Hebrew', status: 0 },
  { id: 72, iso: 'gug', name: 'Paraguayan Guaraní', status: 0 },
  { id: 73, iso: 'nor', name: 'Norwegian', status: 0 },
  { id: 74, iso: 'nso', name: 'Pedi', status: 0 },
  { id: 75, iso: 'kat', name: 'Georgian', status: 0 },
  { id: 76, iso: 'tuk', name: 'Turkmen', status: 0 },
  { id: 77, iso: 'tgk', name: 'Tajik', status: 0 },
  { id: 79, iso: 'lao', name: 'Lao', status: 0 },
  { id: 80, iso: 'lit', name: 'Lithuanian', status: 0 },
  { id: 81, iso: 'kir', name: 'Kirghiz', status: 0 },
  { id: 82, iso: 'khk', name: 'Halh Mongolian', status: 0 },
  { id: 83, iso: 'tso', name: 'Tsonga', status: 0 },
  { id: 87, iso: 'mlt', name: 'Maltese', status: 0 },
  { id: 88, iso: 'isl', name: 'Icelandic', status: 0 },
  { id: 89, iso: 'mai', name: 'Maithili', status: 0 },
  { id: 90, iso: 'hau', name: 'Hausa', status: 0 },
  { id: 91, iso: 'snd', name: 'Sindhi', status: 0 },
  { id: 92, iso: 'ibo', name: 'Igbo', status: 0 },
  { id: 93, iso: 'asm', name: 'Assamese', status: 0 },
  { id: 94, iso: 'gaz', name: 'West Central Oromo', status: 0 },
  { id: 95, iso: 'uig', name: 'Uighur', status: 0 },
  { id: 96, iso: 'lua', name: 'Luba-Lulua', status: 0 },
  { id: 97, iso: 'tir', name: 'Tigrinya', status: 0 },
  { id: 98, iso: 'tat', name: 'Tatar', status: 0 },
  { id: 99, iso: 'bem', name: 'Bemba', status: 0 },
  { id: 100, iso: 'wuu', name: 'Wu Chinese', status: 0 },
  { id: 101, iso: 'yue', name: 'Yue Chinese', status: 0 },
  { id: 102, iso: 'cjy', name: 'Jinyu Chinese', status: 0 },
  { id: 103, iso: 'hsn', name: 'Xiang Chinese', status: 0 },
  { id: 104, iso: 'hak', name: 'Hakka Chinese', status: 0 },
  { id: 105, iso: 'nan', name: 'Min Nan Chinese', status: 0 },
  { id: 106, iso: 'gan', name: 'Gan Chinese', status: 0 },
  { id: 107, iso: 'mnp', name: 'Min Bei Chinese', status: 0 },
  { id: 108, iso: 'cdo', name: 'Min Dong Chinese', status: 0 },
  { id: 130, iso: 'cat', name: 'Catalan', status: 0 },
  { id: 131, iso: 'ckb', name: 'Central Kurdish', status: 0 },
  { id: 132, iso: 'mvf', name: 'Peripheral Mongolian', status: 0 },
  { id: 133, iso: 'kng', name: 'Koongo', status: 0 },
  { id: 134, iso: 'uzs', name: 'Southern Uzbek', status: 0 },
  { id: 135, iso: 'fuf', name: 'Pular', status: 0 },
  { id: 136, iso: 'knn', name: 'Konkani', status: 0 },
  { id: 137, iso: 'glg', name: 'Galician', status: 0 },
  { id: 138, iso: 'lin', name: 'Lingala', status: 0 },
  { id: 139, iso: 'aln', name: 'Gheg Albanian', status: 0 },
  { id: 140, iso: 'mni', name: 'Manipuri', status: 0 },
  { id: 141, iso: 'che', name: 'Chechen', status: 0 },
  { id: 142, iso: 'toi', name: 'Tonga (Zambia)', status: 0 },
  { id: 143, iso: 'aar', name: 'Afar', status: 0 },
  { id: 144, iso: 'bod', name: 'Tibetan', status: 0 },
  { id: 150, iso: 'arz', name: 'Egyptian Arabic', status: 0 },
  { id: 151, iso: 'arq', name: 'Algerian Arabic', status: 0 },
  { id: 152, iso: 'ary', name: 'Moroccan Arabic', status: 0 },
  { id: 153, iso: 'apd', name: 'Sudanese Arabic', status: 0 },
  { id: 154, iso: 'acm', name: 'Mesopotamian Arabic', status: 0 },
  { id: 155, iso: 'aeb', name: 'Tunisian Arabic', status: 0 },
  { id: 156, iso: 'apc', name: 'North Levantine Arabic', status: 0 },
  { id: 157, iso: 'ars', name: 'Najdi Arabic', status: 0 },
  { id: 158, iso: 'ayl', name: 'Libyan Arabic', status: 0 },
  { id: 159, iso: 'ajp', name: 'South Levantine Arabic', status: 0 },
  { id: 170, iso: 'hil', name: 'Hiligaynon', status: 0 },
  { id: 171, iso: 'ilo', name: 'Iloko', status: 0 },
  { id: 172, iso: 'war', name: 'Waray (Philippines)', status: 0 },
  { id: 173, iso: 'bcl', name: 'Central Bikol', status: 0 },
  { id: 180, iso: 'gsw', name: 'Swiss German', status: 0 }

   */
];
languages.sort((a, b) => a.name > b.name ? 1 : -1)
export default languages
