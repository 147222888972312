import ArrowBendUpLeft from 'phosphor-svelte/lib/ArrowBendUpLeft'
import ArrowClockwise from 'phosphor-svelte/lib/ArrowClockwise'
import ArrowDown from 'phosphor-svelte/lib/ArrowDown'
import ArrowLeft from 'phosphor-svelte/lib/ArrowLeft'
import ArrowRight from 'phosphor-svelte/lib/ArrowRight'
import ArrowsLeftRight from 'phosphor-svelte/lib/ArrowsLeftRight'
import ArrowUp from 'phosphor-svelte/lib/ArrowUp'
import Bell from 'phosphor-svelte/lib/Bell'
import BookmarkSimple from 'phosphor-svelte/lib/BookmarkSimple'
import BookOpenText from 'phosphor-svelte/lib/BookOpenText'
import CalendarBlank from 'phosphor-svelte/lib/CalendarBlank'
import CaretDoubleLeft from 'phosphor-svelte/lib/CaretDoubleLeft'
import CaretDoubleRight from 'phosphor-svelte/lib/CaretDoubleRight'
import CaretDown from 'phosphor-svelte/lib/CaretDown'
import CaretLeft from 'phosphor-svelte/lib/CaretLeft'
import CaretRight from 'phosphor-svelte/lib/CaretRight'
import CaretUp from 'phosphor-svelte/lib/CaretUp'
import Certificate from 'phosphor-svelte/lib/Certificate'
import ChalkboardTeacher from 'phosphor-svelte/lib/ChalkboardTeacher'
import ChartBar from 'phosphor-svelte/lib/ChartBar'
import ChartBarHorizontal from 'phosphor-svelte/lib/ChartBarHorizontal'
import ChartLineUp from 'phosphor-svelte/lib/ChartLineUp'
import Chats from 'phosphor-svelte/lib/Chats'
import ChatsCircle from 'phosphor-svelte/lib/ChatsCircle'
import Check from 'phosphor-svelte/lib/Check'
import CheckCircle from 'phosphor-svelte/lib/CheckCircle'
import Checks from 'phosphor-svelte/lib/Checks'
import CheckSquare from 'phosphor-svelte/lib/CheckSquare'
import CheckSquareOffset from 'phosphor-svelte/lib/CheckSquareOffset'
import Clock from 'phosphor-svelte/lib/Clock'
import ClockCounterClockwise from 'phosphor-svelte/lib/ClockCounterClockwise'
import CopySimple from 'phosphor-svelte/lib/CopySimple'
import Crown from 'phosphor-svelte/lib/Crown'
import Cube from 'phosphor-svelte/lib/Cube'
import DiscordLogo from 'phosphor-svelte/lib/DiscordLogo'
import DotsThree from 'phosphor-svelte/lib/DotsThree'
import DotsThreeVertical from 'phosphor-svelte/lib/DotsThreeVertical'
import Envelope from 'phosphor-svelte/lib/Envelope'
import Exam from 'phosphor-svelte/lib/Exam'
import EyeSlash from 'phosphor-svelte/lib/EyeSlash'
import FacebookLogo from 'phosphor-svelte/lib/FacebookLogo'
import Files from 'phosphor-svelte/lib/Files'
import Fire from 'phosphor-svelte/lib/Fire'
import Flag from 'phosphor-svelte/lib/Flag'
import Folder from 'phosphor-svelte/lib/Folder'
import FolderMinus from 'phosphor-svelte/lib/FolderMinus'
import Folders from 'phosphor-svelte/lib/Folders'
import Gear from 'phosphor-svelte/lib/Gear'
import GoogleLogo from 'phosphor-svelte/lib/GoogleLogo'
import GraduationCap from 'phosphor-svelte/lib/GraduationCap'
import Headphones from 'phosphor-svelte/lib/Headphones'
import Heart from 'phosphor-svelte/lib/Heart'
import House from 'phosphor-svelte/lib/House'
import IdentificationCard from 'phosphor-svelte/lib/IdentificationCard'
import Info from 'phosphor-svelte/lib/Info'
import InstagramLogo from 'phosphor-svelte/lib/InstagramLogo'
import Keyboard from 'phosphor-svelte/lib/Keyboard'
import KeyReturn from 'phosphor-svelte/lib/KeyReturn'
import Lightning from 'phosphor-svelte/lib/Lightning'
import LinkedinLogo from 'phosphor-svelte/lib/LinkedinLogo'
import ListChecks from 'phosphor-svelte/lib/ListChecks'
import Lock from 'phosphor-svelte/lib/Lock'
import LockSimple from 'phosphor-svelte/lib/LockSimple'
import LockSimpleOpen from 'phosphor-svelte/lib/LockSimpleOpen'
import MagnifyingGlass from 'phosphor-svelte/lib/MagnifyingGlass'
import MapPin from 'phosphor-svelte/lib/MapPin'
import MedalMilitary from 'phosphor-svelte/lib/MedalMilitary'
import Microphone from 'phosphor-svelte/lib/Microphone'
import Note from 'phosphor-svelte/lib/Note'
import Notebook from 'phosphor-svelte/lib/Notebook'
import Notepad from 'phosphor-svelte/lib/Notepad'
import NotePencil from 'phosphor-svelte/lib/NotePencil'
import PaperPlaneTilt from 'phosphor-svelte/lib/PaperPlaneTilt'
import Pause from 'phosphor-svelte/lib/Pause'
import PencilSimple from 'phosphor-svelte/lib/PencilSimple'
import Play from 'phosphor-svelte/lib/Play'
import Plus from 'phosphor-svelte/lib/Plus'
import Question from 'phosphor-svelte/lib/Question'
import Ranking from 'phosphor-svelte/lib/Ranking'
import RecordIcon from 'phosphor-svelte/lib/Record'
import RedditLogo from 'phosphor-svelte/lib/RedditLogo'
import ShareFat from 'phosphor-svelte/lib/ShareFat'
import ShieldStar from 'phosphor-svelte/lib/ShieldStar'
import SignOut from 'phosphor-svelte/lib/SignOut'
import Sliders from 'phosphor-svelte/lib/Sliders'
import Smiley from 'phosphor-svelte/lib/Smiley'
import SpeakerHigh from 'phosphor-svelte/lib/SpeakerHigh'
import SpeakerLow from 'phosphor-svelte/lib/SpeakerLow'
import Square from 'phosphor-svelte/lib/Square'
import Star from 'phosphor-svelte/lib/Star'
import Stop from 'phosphor-svelte/lib/Stop'
import Student from 'phosphor-svelte/lib/Student'
import Swap from 'phosphor-svelte/lib/Swap'
import TelegramLogo from 'phosphor-svelte/lib/TelegramLogo'
import ThumbsDown from 'phosphor-svelte/lib/ThumbsDown'
import ThumbsUp from 'phosphor-svelte/lib/ThumbsUp'
import Toolbox from 'phosphor-svelte/lib/Toolbox'
import Translate from 'phosphor-svelte/lib/Translate'
import Trash from 'phosphor-svelte/lib/Trash'
import TwitterLogo from 'phosphor-svelte/lib/TwitterLogo'
import User from 'phosphor-svelte/lib/User'
import UserCircleGear from 'phosphor-svelte/lib/UserCircleGear'
import Users from 'phosphor-svelte/lib/Users'
import UsersFour from 'phosphor-svelte/lib/UsersFour'
import UserSound from 'phosphor-svelte/lib/UserSound'
import UsersThree from 'phosphor-svelte/lib/UsersThree'
import WarningCircle from 'phosphor-svelte/lib/WarningCircle'
import WhatsappLogo from 'phosphor-svelte/lib/WhatsappLogo'
import Wrench from 'phosphor-svelte/lib/Wrench'
import X from 'phosphor-svelte/lib/X'
import XCircle from 'phosphor-svelte/lib/XCircle'
import YoutubeLogo from 'phosphor-svelte/lib/YoutubeLogo'

export const posphorIcons = {
  ArrowBendUpLeft,
  ArrowClockwise,
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  ArrowsLeftRight,
  Bell,
  BookOpenText,
  BookmarkSimple,
  CalendarBlank,
  CaretDoubleLeft,
  CaretDoubleRight,
  CaretDown,
  CaretLeft,
  CaretRight,
  CaretUp,
  Certificate,
  ChalkboardTeacher,
  ChartBar,
  ChartBarHorizontal,
  ChartLineUp,
  Chats,
  ChatsCircle,
  Check,
  CheckCircle,
  CheckSquare,
  CheckSquareOffset,
  Checks,
  Clock,
  ClockCounterClockwise,
  CopySimple,
  Crown,
  Cube,
  DiscordLogo,
  DotsThree,
  DotsThreeVertical,
  Envelope,
  Exam,
  EyeSlash,
  FacebookLogo,
  Files,
  Fire,
  Flag,
  Folder,
  FolderMinus,
  Folders,
  Gear,
  GoogleLogo,
  GraduationCap,
  Headphones,
  Heart,
  House,
  IdentificationCard,
  Info,
  InstagramLogo,
  KeyReturn,
  Keyboard,
  Lightning,
  LinkedinLogo,
  ListChecks,
  Lock,
  LockSimple,
  LockSimpleOpen,
  MagnifyingGlass,
  MapPin,
  MedalMilitary,
  Microphone,
  Note,
  NotePencil,
  Notebook,
  Notepad,
  PaperPlaneTilt,
  Pause,
  PencilSimple,
  Play,
  Plus,
  Question,
  Ranking,
  RecordIcon,
  RedditLogo,
  ShareFat,
  ShieldStar,
  SignOut,
  Sliders,
  Smiley,
  SpeakerHigh,
  SpeakerLow,
  Square,
  Star,
  Stop,
  Student,
  Swap,
  TelegramLogo,
  ThumbsDown,
  ThumbsUp,
  Toolbox,
  Translate,
  Trash,
  TwitterLogo,
  User,
  UserCircleGear,
  UserSound,
  Users,
  UsersFour,
  UsersThree,
  WarningCircle,
  WhatsappLogo,
  Wrench,
  X,
  XCircle,
  YoutubeLogo
}
