import languages from '@/config/languages'

import storage from './storage'

export const STAGE = import.meta.env?.VITE_PUBLIC_STAGE || 'TEST'

let CONFIG = {
  API_PUBLIC_URL: 'https://api.langoid.com',
  CHAT_URL: 'wss://chat.langoid.com/',
  FULL_HOST: 'https://langoid.com',
  HOST: 'langoid.com'
}
if (STAGE === 'TEST') {
  CONFIG = {
    API_PUBLIC_URL: 'https://api.brankus.com',
    CHAT_URL: 'wss://chat.brankus.com/',
    FULL_HOST: 'https://demo.brankus.com',
    HOST: 'brankus.com'
  }
}

if (STAGE === 'LIVE') {
  CONFIG = {
    API_PUBLIC_URL: 'https://api.langoid.com',
    CHAT_URL: 'wss://chat.langoid.com/',
    FULL_HOST: 'https://langoid.com',
    HOST: 'langoid.com'
  }
}

if (STAGE === 'LOCAL') {
  CONFIG = {
    API_PUBLIC_URL: 'https://api.lang.com',
    CHAT_URL: 'ws://localhost:9090/',
    FULL_HOST: 'https://lang.com:5555', // 'wss://chat.lang.com/'
    HOST: 'lang.com'
  }
}

if (STAGE === 'SWOOLE') {
  CONFIG = {
    API_PUBLIC_URL: 'https://api.lang.com:9501',
    CHAT_URL: 'ws://localhost:9090/',
    FULL_HOST: 'https://lang.com:5555', // 'wss://chat.lang.com/'
    HOST: 'lang.com'
  }
}

export const API_PUBLIC_URL = CONFIG.API_PUBLIC_URL
export const API_URL = `${API_PUBLIC_URL}/v1`

export const getLearningLanguageId = () => {
  const tmp = location.href.replace(/https:\/\//, '').split('/')[1]
  return tmp.length === 3 ? getIdFromIso(tmp) : 0
}

export function getIdFromIso (iso: string):number {
  return languages?.find((item) => item.iso === iso)?.id || 0
}

export const getInterfaceLanguageId = () => {
  return getIdFromIso(getInterfaceIso())
}

export const getInterfaceIso = () => {
  const userConfig = storage.read('userConfig')
  if (userConfig.locale) return userConfig.locale
  return 'eng'
}

const apiCall = async (url: string, data = {}, method = 'POST') => {
  const languageId = getLearningLanguageId()
  const interfaceId = getInterfaceLanguageId()
  // eslint-disable-next-line no-console
  console.log('APICAll:', languageId, interfaceId, 'url', url)
  return await fetch(`${API_URL}/${url}`, {
    body: JSON.stringify({interfaceId, languageId, ...data}),
    credentials: 'include',
    headers: {
      Authorization: 'Bearer ' + getCookie('jwt'),
      // 'X-FP-API-KEY': 'iphone', //it can be iPhone or your any other attribute
      'Content-Type': 'application/json'
    },
    method
  })
}

export function setCookie (cname: string, cvalue: string, exdays: number) {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  const expires = 'expires=' + d.toUTCString()
  let domainPart
  if (location.host.includes('langoid.com')) {
    domainPart = 'domain=langoid.com;'
  } else {
    if (location.host.includes('lang.com')) {
      domainPart = 'domain=lang.com;'
    } else {
      domainPart = ''
    }
  }
  document.cookie = `${cname}=${cvalue};${expires};${domainPart}path=/;secure`
}

// get or read cookie
export function getCookie (cname: string) {
  const name = cname + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export default apiCall
