<!-- eslint-disable max-len -->
<h1>Unclear sentence meaning</h1>
<p>When sentence could be translated on multiple different ways depending of context here we are providing context.</p>
<p>For example:</p>
<ul>
  <li>You are writing in language where gender is not important, but in translated language translation is different if gender don't match.</li>
  <li>You are using formal variant in one language and informal in other.</li>
  <li>Sentence "That is so big." If we are talking about "book" and "that" in this sentence reffering to book we should say that.</li>
</ul>
<p>Reason for this is maybe not obvious so we will show example: <br />
  English: I worked. <br />
  Russian: Я работал. <br />
  Serbian: Radila sam. <br />
  Here translated from English both translations are correct
</p>
<p>We doing this only for English sentences. If it's done correctly other langauges will just work.</p>
<hr />
<p>On this page you click on any word you want to comment, and form will pop up. In the form you can choose variants (if applicable). There is a field "<strong>Sentence comment</strong>" in that field you can write comment about the whole sentence. Inside "<strong>word comment</strong>" you can write comment about selected word.</p>
<p>All this info is used when translating sentences. So try to not to add too much redundant information, but try to not to ommit what is important.</p>
<p>All info populated inside form is visible without clicking on sentence words.</p>
