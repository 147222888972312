<script lang='ts'>
  import languages from '@/config/languages'

  import { fetchData } from '@/helpers/fetchHelpers'
  import { notifier } from '@/helpers/notifier'

  let language: any, spaceless: any, special: any

  let data: any = {}
  let disableForm = false
  let limit = 100

  const load = async () => {
    const postData = await fetchData('admin/automaticPost', { language, limit, spaceless: !!spaceless.checked, special: !!special.checked })
    if (postData.error) {
      notifier.info(postData.error)
    } else {
      data = postData
      disableForm = false
    }
  }

  function sendForm() {
    disableForm = true
    load()
  }
</script>
{#if disableForm}
  ... Loading
{/if}
<form class='automatic-post-form' class:-hidden={disableForm} on:submit|preventDefault={sendForm}>

  <div>Language
    <div class='custom-select'>
      <select required bind:value={language}>
        <option value=''>Choose</option>
        {#each languages as lang}
          <option value={lang.id}>{lang.name} ({lang.iso})</option>
        {/each}
      </select>
    </div>
  </div>
  <p>
    Spaceless: <input bind:this={spaceless} type='checkbox' value='1' />
    <small>Use this for languages like Chinese if there is no spaces between words</small>
  </p>
  <p>
    Special: <input bind:this={special} type='checkbox' value='1' />
    <small>Use this for languages like Indonesian when you have many words which are created connecting other words together</small>
  </p>
  <input type='text' bind:value={limit} />
  <input disabled={disableForm} type='submit' value='Send' />
</form>

{#if data.output}
  {#each data.output as line}
    <div>{line}
      <hr />
    </div>
  {/each}
  <pre>
    {data.outputText}
  </pre>
{/if}
<style lang='scss'>
  .automatic-post-form {
    &.-hidden {
      display: none;
    }
  }
</style>
