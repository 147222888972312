<script lang='ts'>
  export let data: string[] = []
</script>
{#if data.length > 0}
  <table class='table'>
    <thead>
      <tr>
        {#each Object.keys(data[0]) as name}
          <th>{name}</th>
        {/each}
      </tr>
    </thead>
    <tbody>
      {#each data as row, k}
        <tr>
          {#each Object.values(row) as col}
            <td>{@html col}</td>
          {/each}
        </tr>
      {/each}
    </tbody>
  </table>
{/if}
