<script lang='ts'>
  import Svelecte from 'svelecte'
  import { onMount } from 'svelte'

  import { poses } from '@/helpers/data'
  import { fetchData } from '@/helpers/fetchHelpers'
  import { removeByItemValue, toInt } from '@/helpers/mix'
  import { notifier } from '@/helpers/notifier'
  import { removePostSyntax } from '@/helpers/removePostSyntax'

  import PosTaggingDoc from '@/components/docs/lesson/PosTaggingDoc.svelte'
  import Documentation from '@/components/Documentation.svelte'
  import Overlay from '@/components/Overlay.svelte'

  document.title = 'Lesson: Part of Speech Tagging'

  export let iso = '',
    lessonId = 0

  let meanings: any[] = []
  let sentences: any[] = []
  let lessons: any = {}
  let stats: any = {}
  const sentenceValues: any = {}
  let popupWords: any[] = []
  let popupData: any = {}

  let lastClickedWord: any
  let currentWord = 0, currentSentence = 0

  let lang = 0

  fetchData('mod/posTaggingLoad', {})
    .then((data) => {
      if (data.error) {
        notifier.error((data.error))
      } else {
        lessons = data.lessons
      }
    }).catch((e) => {
      notifier.error(e.message)
    })

  const loadLesson = (id: any) => {
    fetchData('mod/posTaggingLoadLesson', { id })
      .then((data) => {
        if (data.error) {
          notifier.error(data.error)
        } else {
          const tmp = data.sentences
          tmp.forEach((s: any) => {
            s.post = s.post.length ? JSON.parse(s.post) : []
          })
          sentences = tmp
          lang = sentences[0].id_language
          meanings = data.meanings
          stats = data.stats
          if (id !== lessonId) {
            window.history.pushState({}, document.title, `/${iso}/lesson/pos-tagging/${id}`)
          }
        }
      }).catch((e) => {
        notifier.error(e.message)
      })
  }

  function cw (word: string, lang: number) {
    if (!word) return word
    const ARMENIAN = 78
    if (toInt(lang) === ARMENIAN) {
      const ARMENIAN_NON_WORD_CHARS = /(՞|՜|՛)/g
      return word.replace(ARMENIAN_NON_WORD_CHARS, '')?.toLowerCase?.()
    }
    return word?.toLowerCase?.()
  }

  const createPostArray = (all: any, server: any, local: any) => {
    let wordOrder = 0
    /*
    Logic:
    - check if word exist in "local" object, if exist use it
    - check if wor exist in "all" object, if exist use it
    - if not exist, use "server" object
     */
    let localCopy = [...local]
    return server.map((word: any) => {
      ++wordOrder
      const localWord = localCopy.find(w => cw(w.content, lang) === cw(word.value, lang))
      if (localWord) {
        localCopy = removeByItemValue(localCopy, 'content', word.value)
        if (localWord.id_word) {
          return { ...localWord, order_of_word: wordOrder }
        }
      }
      const allWord = all.find((w: any) => typeof word.value === 'string' && typeof w.word2 === 'string' && (cw(w.word2, lang) === cw(word.value, lang)))
      if (allWord) {
        return {
          content: word.value,
          forms: all.filter((w: any) => cw(w.word2, lang) === cw(word.value, lang)).length,
          freq: allWord.frequency,
          id: allWord.id,
          id_wif: allWord.wifs_id,
          id_word: allWord.id,
          isWord: true,
          level: allWord.level,
          order_of_word: wordOrder,
          original: allWord.word,
          pos: allWord.pos,
          wif: allWord.wifs_id
        }
      }
      const empty = {
        content: word.value,
        forms: 0,
        freq: 1000000,
        id: 0,
        id_wif: 0,
        id_word: 0,
        order_of_word: wordOrder,
        original: word.value
      }
      if (word.type === 'word') return { ...empty, isWord: true, level: 'c2', pos: 0, wif: 0 }
      return { ...empty, isWord: false, level: word.type, pos: '-', wif: ' ' }// word is space or interpunction
    })
  }

  const selectWord = (word: any, reset = true) => {
    sentences.forEach((s: any, sentenceIndex: number) => {
      if (s.id === popupData.sentenceId) {
        s.post.forEach((w: any, index: number) => {
          if (index === popupData.index) {
            if (word) {
              const current = sentences[sentenceIndex].post[index] || {}
              sentences[sentenceIndex].post[index] = {
                content: current?.content || word.word2,
                forms: w.forms,
                freq: word.frequency,
                id: word.id,
                id_wif: word.wifs_id,
                id_word: word.id,
                isWord: true,
                level: word.level,
                order_of_word: w.order_of_word,
                original: word.word,
                pos: word.pos,
                type: 'post',
                wif: word.wifs_id
              }
            }
          }
        })
      }
    })
    if (reset && !sentences[currentSentence].post.find((w: any) => w.type === 'word')) {
      popupData = {}
      popupWords = []
    }
    sentences = [...sentences]
  }

  const loadSentence = (sentence: any) => {
    const id = sentence.id
    fetchData('mod/posTaggingLoadSentence', { id, sentence: sentenceValues[id].value })
      .then((data) => {
        if (data.error) {
          notifier.error(data.error)
        } else {
          const postArray = createPostArray(data.words, data.wordsList, sentence.post)
          sentences.forEach((el: any) => {
            if (el.id === id) {
              el.post = postArray
            }
          })
          sentences = [...sentences]
        }
      }).catch((e) => notifier.error(e.message))
  }

  const loadWord = (word: any, sentenceId: number, indexInSentence: number) => {
    const id = word.id
    currentWord = indexInSentence
    lastClickedWord = word.id
    if (!word.id) {
      return
    }
    popupWords = []
    fetchData('mod/posTaggingLoadWord', { id, word: cw(word.content, lang) })
      .then((data) => {
        if (data.error) {
          notifier.error(data.error)
        } else {
          popupData = {
            index: indexInSentence,
            sentenceId
          }
          popupWords = data.words
        }
      }).catch((e) => notifier.error(e.message))
  }
  const saveSentence = (id: number) => {
    const sentence = sentences.find((s: any) => s.id === id)
    sentence.sentence = sentenceValues[id].value
    fetchData('mod/posTaggingSaveWords', { sentence })
      .then((data) => {
        if (data.error) {
          notifier.error(data.error)
        } else {
          notifier.success('Sentence saved')
        }
      }).catch((e) => notifier.error(e.message))
  }

  const closeModal = () => {
    popupWords = []
  }

  const handleOnKeyPress = (e: KeyboardEvent) => {
    if ((e.target as HTMLElement).tagName === 'SELECT') {
      const element = document.querySelector('td')
      if (element) {
        element.blur()
        element.focus()
      }
      e.stopPropagation()
    }
  }

  onMount(() => {
    if (lessonId) {
      loadLesson(lessonId)
    }
    const handleOnKeyDown = (e: KeyboardEvent) => {
      const tagName = (e.target as HTMLElement).tagName
      if (tagName === 'INPUT' || tagName === 'TEXTAREA' || tagName === 'SELECT') {
        if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Escape'].includes(e.key)) {
          return
        }
      }

      if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
        e.preventDefault()
        popupWords = popupWords.map((el, index) => ({ ...el, index }))
        const word = sentences[currentSentence].post[currentWord] || {}
        const selectedWord = popupWords.find(w => w.id === word.id)
        let currentIndex = selectedWord?.index || 0
        if (e.key === 'ArrowUp') {
          currentIndex = currentIndex - 1
          if (currentIndex < 0) currentIndex = popupWords.length - 1
        } else {
          currentIndex = currentIndex + 1
          if (currentIndex >= popupWords.length) currentIndex = 0
        }
        if (popupWords[currentIndex]) {
          selectWord(popupWords[currentIndex], false)
          lastClickedWord = popupWords[currentIndex].id
        } else {
          console.error('index is missing', { currentIndex, currentWord, popupWords })
        }
      } else if (e.key === 'Enter') {
        if (popupWords.length) closeModal()
      } else if (e.key === 'Escape') {
        closeModal()
      } else if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
        e.preventDefault()
        const direction = e.key === 'ArrowLeft' ? -1 : 1
        loadNextWord(direction)
        const level = sentences[currentSentence].post[currentWord]?.level
        if (level === 'int' || level === 'space') {
          loadNextWord(direction)
        }
      }
    }

    const handlePopState = () => {
      const last = parseInt(location.pathname.split('/').pop() || '')
      if (last > 0) {
        loadLesson(last)
      }
    }

    document.addEventListener('keypress', handleOnKeyPress)
    document.addEventListener('keydown', handleOnKeyDown)
    window.addEventListener('popstate', handlePopState)

    return () => {
      document.removeEventListener('keypress', handleOnKeyPress)
      document.removeEventListener('keydown', handleOnKeyDown)
      window.removeEventListener('popstate', handlePopState)
    }
  })

  function loadNextWord (direction: any) {
    popupWords = []
    const sentence = sentences[currentSentence]
    currentWord = currentWord + direction
    if (currentWord >= sentence.post.length) {
      currentSentence++
      currentWord = 0
      if (currentSentence >= sentences.length - 1) {
        currentSentence = sentences.length - 1
      }
    }
    if (currentWord === -1) {
      if (currentSentence > 0) currentSentence--
      else currentWord = 0
      currentWord = sentences[currentSentence].post.length - 1
      if (currentSentence < 0) {
        currentSentence = sentences.length - 1
      }
    }
    const nextWord = sentences[currentSentence].post[currentWord]
    if (nextWord) {
      loadWord(nextWord, sentences[currentSentence].id, currentWord)
    }
  }

  function handleLessonChange (event: CustomEvent) {
    lessonId = parseInt(event.detail?.value)
    if (lessonId)loadLesson(lessonId)
  }
</script>

{#key lessons}
  <div class='svelecte-wrapper'>
    <Svelecte
      options={Object.keys(lessons).map(id => ({ label: `${lessons[id]} - ${id}`, value: id }))}
      placeholder='Select a lesson...'
      bind:value={lessonId}
      on:change={handleLessonChange} />
  </div>
{/key}

{#if popupWords.length}
  <Overlay hidden={false} onClick={closeModal}>
    <div class='popup-wrapper'>
      <table class='table'>
        <tr>
          <th>Id</th>
          <th>Word</th>
          <th>Wif</th>
          <th>Translated</th>
          <th>POS</th>
          <th>Eng</th>
          <th>Level</th>
          <th>Definition</th>
        </tr>
        {#each popupWords as word}
          <tr on:click={() => selectWord(word)}>
            <td class:last={toInt(lastClickedWord) === toInt(word.id)}>{word.id}</td>
            <td>{word.word}</td>
            <td>{word.word2 || ''}</td>
            <td>{word.engWord}</td>
            <td>{poses[word?.pos]?.pos || ''}</td>
            <td>{word.id_english_word}</td>
            <td>{word.level}</td>
            <td>{word.engDefinition}</td>
          </tr>
        {/each}
      </table>
    </div>
  </Overlay>
{/if}
{#if sentences.length}
  <table id='level-checker-table' class='table'>
    <thead>
      <tr>
        <th>#</th>
        <th>Sentence</th>
        <th>Edit</th>
      </tr>
    </thead>
    <tbody id='sentences_for_checking'>
      {#each sentences as sentence, k}
        <tr class='sentence-row' class:-selected={k === currentSentence} on:click={() => { currentSentence = k }} on:keydown={() => { currentSentence = k }}>
          <td>{sentence.id}</td>
          <td><span class='sentence-holder'>

            {#each sentence.post as word, i}
              <span
                class={`word -${(word.word_class || '').toLowerCase()} -${word.level.toLowerCase()}`}
                class:-clickable={word.forms > 1}
                class:-selected={i === currentWord && k === currentSentence}
                role='button'
                tabindex='0'
                title={`id: ${word.id} ${word.original}`}
                on:keypress={() => {}}
                on:click={() => loadWord(word, sentence.id, i)}>
                <em>{word.content}</em>
                {#if word.level !== 'int' && word.level !== 'space'}
                  <b>{word.original}</b>
                {/if}
              </span>
            {/each}
          </span>
          </td>
          <td>
            <div>{removePostSyntax(sentence.english_sentence || '')}</div>
            <textarea bind:this={sentenceValues[sentence.id]} cols='30' rows='1'>{sentence.sentence}</textarea>
            <br />
            <input type='button' value='Load sentence' on:click={() => loadSentence(sentence)} />
            <input type='button' value='Save sentence' on:click={() => saveSentence(sentence.id)} />
          </td>
          <td>{@html meanings[sentence.id_english_sentence].tagged} {@html meanings[sentence.id_english_sentence].sentence}</td>
        </tr>
      {/each}
    </tbody>
  </table>
{:else}
  <h2>Choose lesson in select above</h2>
{/if}
{#each Object.keys(stats) as level}
  <div>
    {level}: {stats[level].length}
  </div>
{/each}
<Documentation>
  <PosTaggingDoc />
</Documentation>
<style lang='scss'>
  .popup-wrapper {
    background: var(--white-1);
  }

  :global(tr):hover {
    > :global(td) {
      background: var(--gray-2) !important;
    }
  }

  .sentence-row.-selected {
    > td {
      background: var(--gray-3) !important;
    }
  }

  .sentence-holder {
    display: flex;
    flex-wrap: wrap;

    > .word {
      margin-bottom: 0.4rem;
      border-bottom: solid transparent 0.4rem;

      &.-clickable {
        border-bottom-color: var(--black-text);
        cursor: pointer;
      }

      &.-selected {
        outline: solid var(--yellow-3) 5px;
      }

      > em,
      > b {
        display: block;
        font-weight: normal;
        font-style: normal;
      }

      > b {
        font-size: 0.8em;
      }

      &.-a0 {
        background: var(--color-neutral);
      }

      &.-a1{
        background: var(--a1);
      }

      &.-a2 {
        background: var(--a2);
      }

      &.-b1 {
        background: var(--b1);
      }

      &.-b2 {
        background: var(--b2);
      }

      &.-c1 {
        background: var(--c1);
      }

      &.-c2 {
        background: var(--c2);
      }

      &.-int {
        background: var(--yellow-1);
      }

      &.-native {
        background: var(--red-3);
      }

      &.-name {
        background: var(--white-1);
      }

      &.-space {
        background-color: var(--yellow-2);
      }
    }

    > span {
      padding: 0.4rem;
      background-color: var(--red-3);
    }
  }

  .svelecte-wrapper {
    max-width: 51.2rem;
  }

  /* stylelint-disable-next-line rscss/class-format */
  .last {
    background: var(--pink-3);
  }
</style>
