import apiCall from './apiCall'

export const fetchData = async (url: string, data: any, errorMessage = 'failed to fetch data') => {
  const res = await apiCall(url, data)
  const returnData = await res.json()
  if (res.status === 401) { // todo - document this and create thicket for unifiing this "Access denied." logic
    // todo - hardcoded message to handle cases where api access is not allowed
    if (returnData === 'Access denied.' || returnData?.error === 'page.401.redirect') {
      window.location = 'https://langoid.com/login?redirect=' + location.href as any
    }
    return new Promise(() => {
      return { error: 'Access to this page is not allowed' }
    })
  }
  if (res.ok) {
    return returnData
  }

  throw new Error(errorMessage + ' ReturnData: ' + JSON.stringify(returnData))
}
