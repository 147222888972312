<script lang='ts'>
  import { onMount } from 'svelte'
  import { navigate } from 'svelte-routing'

  import { API_URL, getCookie, setCookie } from '@/helpers/apiCall'
  import { logger } from '@/helpers/logger'
  import { notifier } from '@/helpers/notifier'

  let email: HTMLInputElement
  let pass: HTMLInputElement

  function handleSubmit () {
    fetch(API_URL + '/auth/login', {
      body: JSON.stringify({ email: email.value, pass: pass.value }),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST'
    })
      .then((result) => {
        result.json().then((data) => {
          if (data.jwt) {
            setCookie('jwt', data.jwt, 7)
            const params = new URLSearchParams(window.location.search)
            const redirect = params.get('redirect')
            if (redirect && !redirect.includes('/login')) {
              location.href = redirect
            } else {
              location.href = '/'
            }
          } else {
            notifier.error('Incorrect data')
          }
        }).catch(e => {
          logger.error(e)
        })
      })
      .catch(() => {
        notifier.error('auth.error')
      })

    return false
  }

  function socialLoginRedirect () {
    const urlParams = new URLSearchParams(window.location.search)
    const jwt = urlParams.get('jwt')
    const message = urlParams.get('message')?.toString() || ''
    if (jwt) {
      setCookie('jwt', jwt, 7)
      location.href = '/?message=' + encodeURIComponent(message)
    }
  }

  onMount(() => {
    const jwt = getCookie('jwt')
    if (jwt) {
      navigate('/')
    } else {
      socialLoginRedirect()
    }
  })

</script>
<div class='login-container'>
  <div class='login-page'>
    <form class='login-form' method='post' on:submit|preventDefault={handleSubmit}>
      <h2>Login</h2>
      <div class='formGroup'>
        <label for='email'>Email</label>
        <input
          bind:this={email}
          id='email'
          data-test='email'
          placeholder='Email'
          type='email'
          name='email' />
      </div>
      <div class='formGroup'>
        <label for='pass'>Password</label>
        <input
          bind:this={pass}
          id='pass'
          data-test='password'
          placeholder='Password'
          type='password'
          name='pass' />
      </div>
      <button class='btn' data-test='loginButton' type='submit'>Login</button>
    </form>
  </div>
</div>
