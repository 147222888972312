<script lang='ts'>
  export let type = 'index'
</script>
<!-- eslint-disable max-len -->
<div class='common-part'>
  <h2>Common part</h2>
  <p>All crud pages follow the same rules.</p>
  <p>They hae "Add" button for adding new record</p>
  <p>Records are shown in table.</p>
  <p>Each record has a button to edit and delete it. This could look different depending of your level.</p>
  <p>On top right is search field, there you can search by multiple fields, you choose field you want to search for, search type and value.</p>
  <p>Below you can see details for specific pages.</p>
  <p>If you click column header you can sort column. If you click once more it will sort in descending order.</p>
</div>
<h1>{type}</h1>
{#if type === 'languages'}
  <strong>Main languages table. Languages from this list are listed on website.</strong>
  <ul>
    <li><strong>language</strong> - Language name</li>
    <li><strong>original_name</strong> - How people speaking that language calling their language TODO - populate values</li>
    <li><strong>iso</strong> - 3 letters code we are using as unique language identifier</li>
    <li><strong>features</strong> - What features for this language are available for learning (JSON)</li>
    <li><strong>status</strong> - new, started, finished - started and finished are listed on website</li>
    <li><strong>highest_level</strong> - Highest level you can learn on Langoid for this language</li>
    <li><strong>inflected</strong> - What POS in this language are inflected (JSON).
    Should be populated, we using these fields for logic for showing other forms for word.</li>
    <li><strong>vpr_order</strong> - grammar rules order for this language (JSON). Generated by <a href='/srp/admin/grammar/rules-order' target='_blank'>rules order</a> moderator page.</li>
  </ul>
{:else if type === 'users'}
  Users
{/if}
<style>
  .common-part {
    max-width: 64rem;
  }
</style>
